<template>
    <component :is="componentName[messageType]" :message-data="messageData" />
</template>

<script>
import AudioMessage from '@/views/chats/messages/message-content/AudioMessage.vue'
import ImageMessage from '@/views/chats/messages/message-content/ImageMessage.vue'
import ShareMessage from '@/views/chats/messages/message-content/ShareMessage.vue'
import StoryMentionMessage from '@/views/chats/messages/message-content/StoryMentionMessage.vue'
import VideoMessage from '@/views/chats/messages/message-content/VideoMessage.vue'
import UnsupportedType from '@/views/chats/messages/message-types/UnsupportedType.vue'
import ReelsMessage from '@/views/chats/messages/message-content/ReelsMessage.vue'
import CarouselMessage from '@/views/chats/messages/message-content/CarouselMessage.vue'

export default {
    name: 'AttachmentType',
    components: {
        VideoMessage,
        StoryMentionMessage,
        ShareMessage,
        ImageMessage,
        ReelsMessage,
        AudioMessage,
        CarouselMessage,
        UnsupportedType,
    },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            componentName: {
                image: 'ImageMessage',
                video: 'VideoMessage',
                ig_reel: 'ReelsMessage',
                audio: 'AudioMessage',
                story_mention: 'StoryMentionMessage',
                share: 'ShareMessage',
                carousel: 'CarouselMessage',
                unsupported: 'UnsupportedType',
            },
        }
    },
    computed: {
        messageType() {
            if (this.messageData?.attachments?.length > 1) {
                return 'carousel'
            } else if (this.componentName[this.messageData?.attachments[0]?.type]) {
                return this.messageData?.attachments[0]?.type
            } else {
                return 'unsupported'
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';
</style>
