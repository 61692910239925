<template>
    <div class="media-load input-button">
        <label for="file-input">
            <svg
                class="input-button-icon"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.5 13C17.9945 13 18.4778 12.8534 18.8889 12.5787C19.3 12.304 19.6205 11.9135 19.8097 11.4567C19.9989 10.9999 20.0484 10.4972 19.952 10.0123C19.8555 9.52732 19.6174 9.08187 19.2678 8.73223C18.9181 8.3826 18.4727 8.1445 17.9877 8.04804C17.5028 7.95157 17.0001 8.00108 16.5433 8.1903C16.0865 8.37952 15.696 8.69995 15.4213 9.11108C15.1466 9.5222 15 10.0055 15 10.5C15 11.163 15.2634 11.7989 15.7322 12.2678C16.2011 12.7366 16.837 13 17.5 13ZM17.5 9.66667C17.6648 9.66667 17.8259 9.71554 17.963 9.80711C18.1 9.89868 18.2068 10.0288 18.2699 10.1811C18.333 10.3334 18.3495 10.5009 18.3173 10.6626C18.2852 10.8242 18.2058 10.9727 18.0893 11.0893C17.9727 11.2058 17.8242 11.2852 17.6626 11.3173C17.5009 11.3495 17.3334 11.333 17.1811 11.2699C17.0288 11.2068 16.8987 11.1 16.8071 10.963C16.7155 10.8259 16.6667 10.6648 16.6667 10.5C16.6667 10.279 16.7545 10.067 16.9107 9.91075C17.067 9.75447 17.279 9.66667 17.5 9.66667Z"
                    fill="black"
                />
                <path
                    d="M23.3333 5H6.66667C6.22464 5 5.80072 5.17559 5.48816 5.48816C5.17559 5.80072 5 6.22464 5 6.66667V23.3333C5 23.7754 5.17559 24.1993 5.48816 24.5118C5.80072 24.8244 6.22464 25 6.66667 25H23.3333C23.7754 25 24.1993 24.8244 24.5118 24.5118C24.8244 24.1993 25 23.7754 25 23.3333V6.66667C25 6.22464 24.8244 5.80072 24.5118 5.48816C24.1993 5.17559 23.7754 5 23.3333 5ZM23.3333 23.3333H6.66667V18.3333L10.8333 14.1667L15.4917 18.825C15.8039 19.1354 16.2264 19.3097 16.6667 19.3097C17.107 19.3097 17.5294 19.1354 17.8417 18.825L19.1667 17.5L23.3333 21.6667V23.3333ZM23.3333 19.3083L20.3417 16.3167C20.0294 16.0062 19.607 15.832 19.1667 15.832C18.7264 15.832 18.3039 16.0062 17.9917 16.3167L16.6667 17.6417L12.0083 12.9833C11.6961 12.6729 11.2736 12.4987 10.8333 12.4987C10.393 12.4987 9.9706 12.6729 9.65833 12.9833L6.66667 15.975V6.66667H23.3333V19.3083Z"
                    fill="black"
                />
            </svg>
        </label>
        <input
            id="file-input"
            ref="fileInput"
            class="file-input"
            type="file"
            multiple
            accept=".png, .jpeg, .gif, .mp4, .ogg, .avi, .mov, .webm"
            @change="sendFile"
        />
    </div>
</template>

<script>
import { useChatsStore } from '@/stores/chatsStore'
import { useRoute } from 'vue-router'

export default {
    name: 'MediaLoadComponent',
    setup() {
        const chatsStore = useChatsStore()
        const route = useRoute()

        return { route, chatsStore }
    },
    methods: {
        sendFile(event) {
            Array.from(event.target.files).forEach((file) => {
                this.chatsStore.sendMedia(file)
            })

            this.$refs.fileInput.value = null
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';

.file-input {
    width: 0;
    height: 0;
    display: none;
}
</style>
