<template>
    <div class="search-component">
        <div class="search-wrap">
            <input
                class="search-input text-h3"
                :placeholder="$t('components.search')"
                type="text"
                @input="debouncedSearch"
            />
            <img src="@/assets/icons/search-icon.svg" class="search-icon" alt="" />
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { useChatsStore } from '@/stores/chatsStore'

export default {
    name: 'SearchComponent',
    setup() {
        const chatsStore = useChatsStore()

        return { chatsStore }
    },
    data() {
        return {
            debouncedSearch: null,
        }
    },
    created() {
        this.debouncedSearch = debounce(this.handleInput, 500)
    },
    methods: {
        handleInput(event) {
            const searchValue = event.target.value

            this.chatsStore.conversationsFilters.search = searchValue
            this.chatsStore.conversationsList = []
            this.chatsStore.conversationsPaging = {}
            this.chatsStore.loadConversations()
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';

.search-wrap {
    position: relative;

    .search-input {
        padding: 1.1rem 1.6rem 1.1rem 4rem;
        background: $grey-light;
        border: none;
        border-radius: 0.8rem;
        outline: none;
        width: 25.5rem;
        max-width: 100%;

        &::placeholder {
            color: $grey;
        }
    }

    .search-icon {
        position: absolute;
        top: 50%;
        left: 1.6rem;
        width: 1.8rem;
        height: 1.8rem;
        transform: translateY(-50%);
    }
}

@media screen and (max-width: 575px) {
    .search-wrap {
        .search-input {
            width: 100%;
        }
    }
}
</style>
