<template>
    <CardComponent>
        <template #body>
            <div class="guide-title text-h1 text-center">{{ $t('homePage.title') }}</div>
            <div class="tiny-text">
                <ol>
                    <li>{{ $t('homePage.firstItem') }}</li>
                    <li>{{ $t('homePage.secondItem') }}</li>
                    <li>{{ $t('homePage.thirdItem') }}</li>
                </ol>
            </div>
        </template>
    </CardComponent>
</template>

<script>
import CardComponent from '@/components/general/CardComponent.vue'

export default {
    name: 'HomePage',
    components: { CardComponent },
}
</script>

<style scoped lang="scss">
.guide-title {
    margin-bottom: 5rem;
}
</style>
