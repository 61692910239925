<template>
    <div class="message-text text-h3">{{ $t('components.unsupportedMessage') }}</div>
</template>

<script>
export default {
    name: 'UnsupportedType',
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss" scoped></style>
