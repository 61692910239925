<template>
    <div
        v-dompurify-html="processedMessage"
        class="message-text message-with-link text-h3"
        :class="{ 'reply-message': replyMessage }"
    ></div>
</template>

<script>
export default {
    name: 'TextType',
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },

        replyMessage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        processedMessage() {
            return this.messageData.message.replace(/\bhttps?:\/\/\S+/gi, '<a href="$&">$&</a>')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.reply-message {
    background: #d5f0ff;
}

.message-text {
    max-width: 100%;
}
</style>

<style lang="scss">
.message-with-link {
    a {
        display: inline-block;
        max-width: 100%;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        font-size: 1.4rem;
        line-height: 121%;
        font-weight: 400;
    }
}
</style>
