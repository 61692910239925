<template>
    <div class="form-group" :class="{ invalid: inputError && Object.keys(inputError).length, 'new-style': newStyle }">
        <label v-if="newStyle" class="text-h4" for="">{{ inputLabel }}</label>
        <input
            :value="inputValue"
            :type="inputInnerType"
            :placeholder="inputPlaceholder"
            :class="{ 'toggled-input': passwordToggler }"
            class="text-h2"
            @input="$emit('update:modelValue', $event.target.value)"
        />

        <div
            v-if="passwordToggler"
            class="password-toggler"
            :class="{ toggled: inputInnerType !== 'password' }"
            @click="handlePassToggle"
        >
            <img src="@/assets/eye-closed.svg" class="password-eye eye-closed" alt="" />
            <img src="@/assets/eye-opened.svg" class="password-eye eye-opened" alt="" />
        </div>

        <div v-if="inputError && Object.keys(inputError).length" class="input-error text-h5">
            <template v-for="(item, index) in inputError" :key="index">
                <span class="text-h5">
                    {{ item.$message }}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputComponent',
    props: {
        newStyle: {
            type: Boolean,
            default: false,
        },
        inputError: {
            type: Object,
            default: () => {},
        },
        inputLabel: {
            type: String,
            default: '',
        },
        inputPlaceholder: {
            type: String,
            default: '',
        },
        inputValue: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            default: 'text',
        },
        passwordToggler: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            inputInnerType: this.inputType,
        }
    },
    methods: {
        handlePassToggle() {
            if (this.inputInnerType === 'password') {
                this.inputInnerType = 'text'
            } else {
                this.inputInnerType = 'password'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.form-group {
    position: relative;
    margin-bottom: 2rem;
    width: 100%;

    &.invalid {
        input {
            border: 1px solid $red;
            color: $red;
        }
    }

    &.new-style {
        label {
            padding-left: 2.2rem;
            color: $grey;
        }

        input {
            padding: 1.75rem 2.2rem;
            background: $white;
            border-radius: 2rem;

            &::placeholder {
                color: $grey;
            }
        }

        .input-error {
            padding: 0 2.2rem;
            top: 7.5rem;
        }
    }

    input {
        width: 100%;
        padding: 1.5rem;
        border-radius: 0.8rem;
        outline: none;
        background: $grey_light;
        border: 1px solid $grey_light;

        &.toggled-input {
            padding-right: 5.2rem;
        }

        &::placeholder {
            color: $grey;
        }
    }

    .password-toggler {
        position: absolute;
        top: calc(50% + 1.1rem);
        right: 2.2rem;
        transform: translateY(-50%);
        cursor: pointer;

        &.toggled {
            .eye-closed {
                display: block;
            }

            .eye-opened {
                display: none;
            }
        }

        .password-eye {
            width: 2rem;
            height: 2rem;
        }

        .eye-closed {
            display: none;
        }
    }

    .input-error {
        position: absolute;
        top: 5.3rem;
        padding: 0 1.5rem;

        span {
            color: $red;
            margin-right: 0.5rem;
        }
    }
}
</style>
