<template>
    <div class="card">
        <div class="card-header">
            <slot name="header"></slot>
        </div>
        <div class="card-body">
            <slot name="body"></slot>
        </div>
        <div class="card-footer">
            <slot name="footer"></slot>
        </div>

        <slot name="loader"></slot>
    </div>
</template>

<script>
export default {
    name: 'CardComponent',
}
</script>

<style scoped lang="scss">
@import '@/scss/common/_colors.scss';

.card {
    display: flex;
    flex-direction: column;
    padding: 5rem 3rem;
    border-radius: 2rem;
    background-color: rgba($grey-light, 0.4);
    width: 100%;
    max-width: 91.5rem;
    margin: 0 auto;
    height: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;

    &-header {
        padding-bottom: 3rem;
    }

    &-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 5rem;
        overflow: hidden;
    }

    &-footer {
        display: flex;
    }
}

@media screen and (max-width: 575px) {
    .card {
        padding: 0;
        border-radius: unset;
        background-color: transparent;

        &-header {
            padding-right: 8rem;
        }

        &-footer {
            flex-wrap: wrap;
        }
    }
}
</style>

<style lang="scss">
.card-footer {
    & > * {
        &:last-child {
            margin-left: 3rem;
        }

        &:first-child {
            margin-left: auto;
        }
    }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
    .card-footer {
        & > * {
            &:last-child {
                margin-left: 2rem;
            }

            &:first-child {
                margin-left: auto;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .card-footer {
        & > * {
            margin: 0 0 1.5rem;
            width: 100%;
            max-width: 100% !important;

            &:last-child {
                margin: 0;
            }
        }
    }
}
</style>
