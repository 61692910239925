<template>
    <div class="conversation-content">
        <div class="conversation-info">
            <div class="info-top-row">
                <router-link class="back-btn" :to="{ name: 'chats' }">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L11.2986 14.2872C11.2967 14.2891 11.2948 14.291 11.2929 14.2929C10.9023 14.6834 10.9023 15.3166 11.2929 15.7071L11.2929 15.7071L17.2929 21.7071C17.6834 22.0976 18.3166 22.0976 18.7071 21.7071C19.0976 21.3166 19.0976 20.6834 18.7071 20.2929L13.4142 15L18.7071 9.70711Z"
                            fill="black"
                        />
                    </svg>
                </router-link>
                <div class="middle-block">
                    <div class="user-name text-h3-bold">
                        {{ chatsStore.conversationInfo?.title }}
                    </div>

                    <StatusComponent
                        :selected-status-id="conversationStatus"
                        :conversation-id="route.params.conversation_id"
                        :statuses="statusesStore.nonSystemStatusesList"
                        :centered-mode="true"
                    />
                </div>

                <a :href="findParticipant()?.profile_link" @click="navigate($event)">
                    <img
                        :src="
                            chatsStore.conversationInfo?.picture != ''
                                ? chatsStore.conversationInfo.picture
                                : require('@/assets/icons/instagram-icon.svg')
                        "
                        class="user-img"
                        alt=""
                    />
                </a>
            </div>
            <div class="info-bottom-row">
                <img v-if="getChannelImg" :src="getChannelImg" class="channel-img" alt="" />
                <span class="channel-name text-h5 text-grey">
                    {{ getChannelName }}
                </span>
            </div>
            <LoaderComponent v-if="chatsStore.loadingStatusChange.includes(route.params.conversation_id)" />
        </div>

        <div ref="messagesList" class="messages-list">
            <MessageWrapper v-for="item in chatsStore.messagesList" :key="item.id" :message-data="item" />
            <infinite-loading
                target=".messages-list"
                :top="true"
                :distance="10"
                :firstload="false"
                @infinite="infiniteHandler"
            ></infinite-loading>
        </div>

        <MessageInputWrapper />

        <LoaderComponent v-if="chatsStore.loadingMessages" />
    </div>
</template>

<script>
import lodash from 'lodash'
import { useChatsStore } from '@/stores/chatsStore'
import { useRoute } from 'vue-router'
import LoaderComponent from '@/components/general/LoaderComponent.vue'
import MessageWrapper from '@/views/chats/messages/MessageWrapper.vue'
import MessageInputWrapper from '@/views/chats/message-input/MessageInputWrapper.vue'
import StatusComponent from '@/components/general/StatusComponent.vue'
import { useStatusesStore } from '@/stores/statusesStore'
import { socket } from '@/services/socket'
import { useChannelsStore } from '@/stores/channelsStore'

export default {
    name: 'ChatItem',
    components: { StatusComponent, MessageInputWrapper, MessageWrapper, LoaderComponent },
    inject: ['eventBus'],
    setup() {
        const chatsStore = useChatsStore()
        const statusesStore = useStatusesStore()
        const channelsStore = useChannelsStore()
        const route = useRoute()

        return { route, chatsStore, statusesStore, channelsStore }
    },
    computed: {
        currentConversation() {
            return lodash.find(this.chatsStore.conversationsList, (conversation) => {
                return conversation.api_id === parseInt(this.route.params.conversation_id, 10)
            })
        },

        conversationStatus() {
            return (
                this.currentConversation?.status_id ??
                this.chatsStore.conversationInfo?.status_id ??
                this.$t('components.status')
            )
        },

        getChannelName() {
            return this.channelsStore.channelsList.find(
                (channel) => channel.id === this.currentConversation?.channel_id
            )?.name
        },

        getChannelImg() {
            return this.channelsStore.channelsList.find(
                (channel) => channel.id === this.currentConversation?.channel_id
            )?.profile_picture_url
        },
    },
    watch: {
        'route.params'(params) {
            if (params.conversation_id) {
                socket.emit('conversation_close')
                socket.emit('conversation_open', {
                    conversation_id: parseInt(params.conversation_id, 10),
                })
                this.chatsStore.loadMessages(parseInt(params.conversation_id, 10))
            }
        },
    },
    mounted() {
        socket.on('messages.list', this.chatsStore.renderMessages)
        socket.on('seen_message', this.onMessageSeen)
        socket.on('reaction', this.onMessageReaction)
        socket.emit('conversation_open', {
            conversation_id: parseInt(this.route.params.conversation_id, 10),
        })
        this.chatsStore.loadMessages(parseInt(this.route.params.conversation_id, 10))
    },
    unmounted() {
        socket.off('messages.list')
        socket.off('seen_message')
        socket.off('reaction')
        socket.emit('conversation_close')
    },
    methods: {
        findParticipant() {
            for (let useId in this.chatsStore.messagesParticipants) {
                if (this.chatsStore.messagesParticipants[useId].channel_account == 0) {
                    return this.chatsStore.messagesParticipants[useId]
                }
            }

            return {}
        },

        navigate(event) {
            this.eventBus.emit('navigateToUserProfile', this.findParticipant())
            event.preventDefault()
        },

        isCurrentConversation(data) {
            return parseInt(this.route.params.conversation_id, 10) === data.conversations.api_id
        },

        onMessageSeen(data) {
            if (this.isCurrentConversation(data)) {
                this.chatsStore.onMessageSeen(data.messages)
            }
        },

        onMessageReaction(data) {
            if (this.isCurrentConversation(data)) {
                this.chatsStore.onMessageReaction(data.messages.id, data.messages.reactions[0])
            }
        },

        infiniteHandler() {
            if (!this.chatsStore.loadingMessages) {
                this.chatsStore.loadMessages(parseInt(this.route.params.conversation_id, 10), true).then(() => {
                    this.$refs.messagesList.scrollTop -= 1
                    this.$refs.messagesList.scrollTop += 1
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.conversation-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding-bottom: 2.5rem;
}

.conversation-info {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;
    background: $grey_light;

    .info-top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .back-btn {
            display: none;
        }

        .middle-block {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .user-img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
        }
    }

    .info-bottom-row {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 2px dashed $white;
        padding-right: 4rem;

        .channel-img {
            margin-right: 0.8rem;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
        }
    }
}

.messages-list {
    padding: 3rem;
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    overflow-y: auto;
}

@media screen and (max-width: 767px) {
    .conversation-info {
        padding: 1rem 1.5rem;

        .info-top-row {
            .back-btn {
                display: flex;
            }
        }

        .info-bottom-row {
            padding-left: 3rem;
        }
    }

    .messages-list {
        padding: 3rem 1.5rem;
    }
}
</style>
