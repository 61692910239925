<template>
    <div class="chats-page">
        <div class="chats-header">
            <div class="header-top">
                <h2 class="chats-title text-h2-bold">{{ $t('chatsPage.allChats') }}</h2>

                <div class="header-inner">
                    <ConversationsFilter />
                    <SearchComponent />
                </div>
            </div>
            <div class="header-bottom">
                <div class="statuses-row">
                    <div
                        class="status text-h3"
                        :class="{ active: !chatsStore.conversationsFilters?.statuses?.length }"
                        @click="clearStatusesFilters"
                    >
                        Всі статуси
                    </div>
                    <div
                        v-for="(item, index) in statusesStore.statusesList"
                        :key="index"
                        class="status text-h3"
                        :class="{ active: item.active }"
                        @click="filterByStatus(item)"
                    >
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="conversations-col chats-col col-md-6">
                    <div class="conversations-list">
                        <template v-for="(conversation, index) in chatsStore.conversationsList" :key="index">
                            <ConversationComponent
                                v-if="conversation && Object.keys(conversation).length"
                                :conversation="conversation"
                                :statuses="statusesStore.nonSystemStatusesList"
                            />
                        </template>
                        <infinite-loading
                            target=".conversations-list"
                            :distance="10"
                            @infinite="infiniteHandler"
                        ></infinite-loading>
                    </div>
                    <LoaderComponent v-if="chatsStore.loadingConversations" />
                </div>
                <div class="messages-col chats-col col-md-6" :class="{ opened: conversationOpen }">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ConversationComponent from '@/components/general/ConversationComponent.vue'
import { useStatusesStore } from '@/stores/statusesStore'
import { useChatsStore } from '@/stores/chatsStore'
import LoaderComponent from '@/components/general/LoaderComponent.vue'
import { useChannelsStore } from '@/stores/channelsStore'
import lodash from 'lodash'
import { useRoute } from 'vue-router'
import { socket } from '@/services/socket'
import ConversationsFilter from '@/views/chats/ConversationsFilter.vue'
import { useManagersStore } from '@/stores/managersStore'
import SearchComponent from '@/components/general/SearchComponent.vue'

export default {
    name: 'ChatsPage',
    components: { SearchComponent, ConversationsFilter, LoaderComponent, ConversationComponent },
    setup() {
        const statusesStore = useStatusesStore()
        const chatsStore = useChatsStore()
        const channelsStore = useChannelsStore()
        const managersStore = useManagersStore()
        const route = useRoute()

        return { statusesStore, managersStore, chatsStore, channelsStore, route }
    },
    data() {
        return {
            conversationOpen: false,
        }
    },
    watch: {
        'route.params'(params) {
            params?.conversation_id ? (this.conversationOpen = true) : (this.conversationOpen = false)
        },
    },
    async mounted() {
        this.route.params?.conversation_id ? (this.conversationOpen = true) : (this.conversationOpen = false)
        this.chatsStore.conversationsList = []
        this.chatsStore.conversationsPaging = {}
        this.statusesStore.loadStatuses().then(() => {
            this.setActiveStatuses()
        })

        await this.channelsStore.loadChannels()
        await this.managersStore.loadManagers()

        socket.on('conversations_change_status', this.updateStatus)
        socket.on('conversations_mark_as_read', this.conversationRead)
        socket.on('delete_message', this.onMessageDelete)
    },
    unmounted() {
        socket.off('delete_message')
    },
    methods: {
        updateStatus(data) {
            this.chatsStore.syncConversationStatus(
                parseInt(data.conversation_id, 10),
                data.status_id,
                parseInt(this.route.params.conversation_id, 10)
            )
        },

        conversationRead(data) {
            lodash.find(this.chatsStore.conversationsList, (conversation) => {
                if (conversation.api_id === data.conversation_id) {
                    conversation.is_read = true
                }
            })

            if (this.chatsStore.conversationInfo.id === data.conversation_id) {
                this.chatsStore.conversationInfo.is_read = true
            }
        },

        onMessageDelete(data) {
            let deletedMessage = lodash.find(this.chatsStore.messagesList, { id: data.messages.id })
            let visibleConversation = lodash.find(this.chatsStore.conversationsList, {
                api_id: data.conversations.api_id,
            })

            if (visibleConversation && visibleConversation.last_message.id === deletedMessage.id) {
                this.handleConversationLastMessageDelete(visibleConversation, data.conversations)
            }

            if (deletedMessage) {
                this.handleMessageDelete(deletedMessage)
            }
        },

        handleMessageDelete(message) {
            const validKeys = ['created_time', 'message_id', 'id', 'from']

            Object.keys(message).forEach((key) => {
                if (!validKeys.includes(key)) {
                    message[key] = null
                }
            })

            message.type = 'deleted'
        },

        handleConversationLastMessageDelete(oldConversationData, newConversationData) {
            oldConversationData.last_message = newConversationData.last_message
        },

        infiniteHandler() {
            this.chatsStore.loadConversations()
        },

        filterByStatus(status) {
            status.active = !status.active
            const activeStatuses = this.statusesStore.statusesList
                .filter((status) => status.active)
                .map((status) => status.id)

            if (activeStatuses.length) {
                this.chatsStore.conversationsFilters.statuses = activeStatuses
            } else {
                delete this.chatsStore.conversationsFilters.statuses
            }

            this.chatsStore.conversationsList = []
            this.chatsStore.conversationsPaging = {}
            this.chatsStore.loadConversations()
        },

        setActiveStatuses() {
            if (this.chatsStore.conversationsFilters?.statuses?.length) {
                this.statusesStore.setActiveStatuses(this.chatsStore.conversationsFilters.statuses)
            }
        },

        clearStatusesFilters() {
            this.statusesStore.statusesList.map((status) => (status.active = false))
            this.chatsStore.conversationsList = []
            this.chatsStore.conversationsFilters.statuses = []
            this.chatsStore.conversationsPaging = {}
            this.chatsStore.loadConversations()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.chats-page {
    width: 100%;
    height: 100%;
    max-width: 123rem;
    margin: 0 auto;
    background: rgba($grey_light, 0.4);
    border-radius: 2rem;
}

.container-fluid {
    height: calc(100% - 15rem);
}

.row,
.chats-col {
    height: 100%;
}

.conversations-col {
    padding-top: 3rem;
    padding-left: 0;
    position: relative;
}

.chats-header {
    padding: 3rem 3rem 0 3rem;

    .header-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;

        .header-inner {
            display: flex;
            align-items: center;
        }

        .conversations-filter {
            display: flex;
            justify-content: flex-end;
            min-width: 9rem;
            margin-right: 5rem;
        }
    }

    .header-bottom {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $grey_light;
    }
}

.statuses-row {
    display: flex;
    overflow-x: auto;
    padding-bottom: 8px;

    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        background-color: $grey-light;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba($grey, 0.25);
        border-radius: 8px;
        height: 8px;
    }

    .status {
        padding: 0.4rem 1.6rem 0.5rem;
        background: $grey-light;
        border-radius: 0.8rem;
        margin-right: 1.6rem;
        transition: all ease-in-out 0.3s;
        cursor: pointer;
        white-space: nowrap;
        user-select: none;

        &.active {
            background: $blue;
            color: $white;
        }

        &:not(.active):hover {
            background: $grey;
            color: $white;
        }
    }
}

.conversations-list {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
}

@media screen and (max-width: 991px) {
    .conversations-col {
        width: 35%;
    }

    .messages-col {
        width: 65%;
    }

    .conversation-item {
        padding: 1rem 2rem;
    }
}

@media screen and (max-width: 767px) {
    .chats-page {
        position: relative;
        background: transparent;
        display: flex;
        flex-direction: column;
    }

    .container-fluid {
        height: 100%;
        overflow-y: auto;
        flex-grow: 1;
    }

    .conversations-col {
        width: 100%;

        padding-right: 0;
        padding-bottom: 2.5rem;
    }

    .messages-col {
        background: $white;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        transform: translateX(100%);
        box-shadow: 0 0 1.5rem rgba($black, 0.3);

        &.opened {
            z-index: 12;
            transform: translateX(0);
            transition: transform ease-in-out 0.3s;
        }
    }
}

@media screen and (max-width: 575px) {
    .chats-header {
        .header-top {
            align-items: flex-start;
            flex-direction: column;

            .header-inner {
                width: 100%;
                margin-top: 2rem;

                .conversations-filter {
                    margin: 0 0 0 2rem;
                    order: 1;
                }

                .search-component {
                    width: 100%;
                    order: 0;
                }
            }
        }
    }
}

@media (hover: none) {
    .statuses-row {
        .status {
            &:not(.active):hover {
                background: $grey-light;
                color: $black;
            }
        }
    }
}
</style>
