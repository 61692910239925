<template>
    <div class="main-layout">
        <div class="nav-burger" @click="showNavbar">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L21 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 10L21 10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 19L21 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <NavbarComponent :navbar-opened="navbarOpened" @hide-navbar="navbarOpened = false" />
        <div
            class="page-content"
            :class="{
                'large-content': $route.matched.some((route) => route.name === 'chats'),
            }"
        >
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavbarComponent from '@/components/Navbar.vue'

export default {
    name: 'MainLayout',
    components: { NavbarComponent },
    data() {
        return {
            navbarOpened: false,
        }
    },
    watch: {
        '$route.path'() {
            this.navbarOpened = false
        },
    },

    methods: {
        showNavbar() {
            this.navbarOpened = true
        },
    },
}
</script>

<style lang="scss" scoped>
.main-layout {
    display: flex;
    position: relative;
}

.page-content {
    display: flex;
    flex-grow: 1;
    height: 100%;
    padding: 7rem 3rem 6rem;
    overflow-y: auto;

    &.large-content {
        padding: 3rem;
    }
}

.main-layout,
.container-fluid,
.row {
    height: 100%;
}

.nav-burger,
.nav-trigger {
    display: none;
}

@media screen and (max-width: 767px) {
    .page-content {
        &.large-content {
            padding: 0;
            overflow: hidden;
        }
    }
}

@media screen and (max-width: 575px) {
    .page-content {
        padding: 3rem 1.5rem 5rem;
    }

    .nav-burger {
        position: absolute;
        top: 1.5rem;
        right: 0;
        padding: 1.5rem;
        display: flex;
        z-index: 8;
    }

    .nav-trigger {
        display: flex;
        width: 100%;
        height: 4rem;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
    }
}
</style>
