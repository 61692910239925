<template>
    <div class="carousel-message">
        <div v-if="messageData.attachments[0].type === 'image'" class="type-image">
            <img :src="messageData.attachments[0]?.url" alt="" @click="showPopup = true" />
        </div>
        <div v-if="messageData.attachments[0].type === 'video'" class="type-video">
            <video @click="showPopup = true">
                <source :src="messageData.attachments[0]?.url" type="video/mp4" />
            </video>
            <img class="video-play-btn" src="@/assets/icons/video-play-icon.svg" alt="" />
        </div>
        <div class="attachments-quantity text-h4-bold">+{{ messageData.attachments.length - 1 }}</div>
        <transition name="popup-fade">
            <popup-component v-if="showPopup" :hide-loader="false" @close-popup="hidePopup">
                <Swiper
                    :modules="[Navigation]"
                    :navigation="true"
                    :slides-per-view="1"
                    :space-between="30"
                    @slide-change="onSlideChange"
                >
                    <SwiperSlide v-for="(attachment, index) in messageData.attachments" :key="index">
                        <img
                            v-if="attachment.type === 'image'"
                            v-show="mediaLoaded[index]"
                            class="popup-media"
                            :src="attachment?.url"
                            alt=""
                            @load="onMediaLoaded(index)"
                        />
                        <video v-if="attachment.type === 'video'" ref="video" autoplay controls class="popup-media">
                            <source :src="attachment?.url" type="video/mp4" />
                        </video>
                    </SwiperSlide>
                </Swiper>
            </popup-component>
        </transition>
    </div>
</template>

<script>
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import PopupComponent from '@/components/general/PopupComponent.vue'

export default {
    name: 'CarouselMessage',
    components: { Swiper, SwiperSlide, PopupComponent },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            showPopup: false,
            mediaLoaded: {},
        }
    },
    methods: {
        Navigation,

        onMediaLoaded(index) {
            this.mediaLoaded[index] = true
        },

        hidePopup() {
            this.mediaLoaded = {}
            this.showPopup = false
        },

        onSlideChange() {
            const videos = this.$refs.video

            if (videos) {
                videos.forEach((video) => {
                    video.pause()
                })
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/styles';

.carousel-message {
    position: relative;
    padding-top: 3px;
}

.type-image {
    position: relative;
    display: flex;
    height: 15rem;
    width: 15rem;
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 3px -3px 2px 3px #c8c8c8;

    &:hover {
        cursor: pointer;

        img {
            filter: brightness(0.8);
        }
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
    }
}

.type-video {
    position: relative;
    display: flex;
    height: 15rem;
    width: 15rem;
    border-radius: 0.8rem;
    overflow: hidden;
    background: black;
    box-shadow: 3px -3px 2px 3px #c8c8c8;

    video {
        max-height: 20rem;
        height: 100%;
    }

    &:hover {
        cursor: pointer;

        video {
            filter: brightness(0.8);
        }
    }

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
    }

    .video-play-btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 3rem;
        height: 3rem;
        pointer-events: none;
    }
}

.attachments-quantity {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    background: $white;
    border-radius: 50%;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep .swiper-button-prev:after,
:deep .swiper-button-next:after {
    color: $blue;
    text-shadow: 0 0 2px rgba($black, 0.5);
}

.popup-media {
    max-width: 100%;
    max-height: 100%;
}
</style>
