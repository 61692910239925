<template>
    <div
        class="input-wrap"
        :class="{ focus: inputFocused, 'dropdown-opened': replyDropdownOpened }"
        @click="markConversationAsRead"
    >
        <input
            v-model="message"
            type="text"
            class="message-input text-h4"
            :placeholder="$t('components.sendMessage')"
            @keyup.enter="sendMessage"
            @focusin="inputFocused = true"
            @focusout="inputFocused = false"
        />

        <div class="right-side-buttons">
            <template v-if="message.length === 0">
                <QuickReplyComponent @reply-dropdown-opened="changeInputStyles" @select-reply="selectReply" />
                <AudioRecordComponent />
                <MediaLoadComponent />
            </template>

            <div v-else class="send-btn" @click="sendMessage">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="10" stroke="#000000" stroke-width="1.5" />
                    <path
                        d="M19.5303 15.5303C19.8232 15.2374 19.8232 14.7626 19.5303 14.4697L14.7574 9.6967C14.4645 9.40381 13.9896 9.40381 13.6967 9.6967C13.4038 9.98959 13.4038 10.4645 13.6967 10.7574L17.9393 15L13.6967 19.2426C13.4038 19.5355 13.4038 20.0104 13.6967 20.3033C13.9896 20.5962 14.4645 20.5962 14.7574 20.3033L19.5303 15.5303ZM11 14.25C10.5858 14.25 10.25 14.5858 10.25 15C10.25 15.4142 10.5858 15.75 11 15.75L11 14.25ZM19 14.25L11 14.25L11 15.75L19 15.75L19 14.25Z"
                        fill="#000000"
                    />
                </svg>
            </div>
        </div>
        <LoaderComponent v-if="chatsStore.sendingMessage" />
    </div>
</template>
<script>
import { useChatsStore } from '@/stores/chatsStore'
import MediaLoadComponent from '@/views/chats/message-input/components/MediaLoadComponent.vue'
import { useRoute } from 'vue-router'
import LoaderComponent from '@/components/general/LoaderComponent.vue'
import AudioRecordComponent from '@/views/chats/message-input/components/AudioRecordComponent.vue'
import QuickReplyComponent from '@/views/chats/message-input/components/QuickReplyComponent.vue'

export default {
    name: 'MessageInputWrapper',
    components: { QuickReplyComponent, AudioRecordComponent, LoaderComponent, MediaLoadComponent },
    setup() {
        const chatsStore = useChatsStore()
        const route = useRoute()

        return { route, chatsStore }
    },
    data() {
        return {
            replyDropdownOpened: false,
            inputFocused: false,
            message: '',
        }
    },
    methods: {
        markConversationAsRead() {
            this.chatsStore.markConversationAsRead()
        },

        changeInputStyles(condition) {
            this.replyDropdownOpened = condition
        },

        selectReply(text) {
            this.message = text
        },

        sendMessage() {
            if (this.message.trim().length > 0) {
                this.chatsStore.sendMessage(this.message, 'text')
                this.message = ''
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '@/scss/common/colors';

.input-wrap {
    position: relative;
    width: calc(100% - 6rem);
    margin: 0 3rem;
    border-radius: 1rem;
    min-height: 5.8rem;
    overflow: hidden;
    border: 2px solid $grey_light;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    background: $white;

    &.focus {
        border: 2px solid $blue;
        outline: none;
    }

    &.dropdown-opened {
        position: static;
        overflow: unset;
    }

    .message-input {
        border: none;
        width: 100%;
        outline: none;
        height: 5.4rem;
        padding-right: 1.5rem;
        font-size: 1.6rem;

        &::placeholder {
            color: $black;
        }
    }
}

.right-side-buttons {
    display: flex;

    &.audio-recording {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        transform: none;
    }

    .input-button {
        margin-left: 0.6rem;
        height: 3rem;

        &:first-of-type {
            margin-left: 0;
        }
    }

    .send-btn {
        display: flex;

        &:hover {
            cursor: pointer;

            circle {
                stroke: $blue;
            }

            path {
                fill: $blue;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .input-wrap {
        width: calc(100% - 3rem);
        margin: 0 1.5rem;
    }
}
</style>

<style lang="scss">
@import '@/scss/common/colors';

.input-button-icon {
    display: flex;

    path {
        fill: $black;
        transition: all ease-in-out 0.3s;
    }

    &:hover {
        cursor: pointer;

        path {
            fill: $blue;
        }
    }
}
</style>
