import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetchWrapper'
import { createToaster } from '@meforma/vue-toaster'
import i18n from '@/i18n'

const toaster = createToaster({ position: 'bottom' })
const baseRoute = '/quick-answers'

export const useQuickRepliesStore = defineStore('quickRepliesStore', {
    state: () => ({
        quickRepliesList: [],
        quickReplyEdit: {},
        loadingQuickReplies: false,
    }),
    actions: {
        async loadQuickReplies() {
            this.loadingQuickReplies = true
            const lastId = this.quickRepliesList[this.quickRepliesList.length - 1]?.id

            return await fetchWrapper('/quick-answers/get-list', 'POST', {
                last_id: lastId,
            })
                .then((response) => {
                    const { response: answersResponse } = response

                    this.quickRepliesList = this.quickRepliesList.concat(answersResponse.quick_answers)

                    return answersResponse.quick_answers
                })
                .finally(() => {
                    this.loadingQuickReplies = false
                })
        },

        async deleteQuickReply(id) {
            await fetchWrapper(`${baseRoute}/delete`, 'DELETE', { id }).then(() => {
                toaster.success(i18n.global.t('repliesPage.deletedToast'))
            })
            this.quickRepliesList = this.quickRepliesList.filter((object) => {
                return object.id !== id
            })
        },

        async addQuickReply(data) {
            await fetchWrapper(`${baseRoute}/add`, 'POST', data).then(() => {
                toaster.success(i18n.global.t('repliesPage.addedToast'))
            })
            this.quickRepliesList = []
        },

        async editQuickReply(data) {
            await fetchWrapper(`${baseRoute}/update`, 'PUT', data).then(() => {
                toaster.success(i18n.global.t('repliesPage.editedToast'))
            })
            this.quickRepliesList = []
        },
    },
})
