<template>
    <div class="full-screen-page">
        <div class="container-fluid">
            <div class="page-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FullPageLayout',
}
</script>

<style lang="scss">
.full-screen-page {
    display: flex;
    align-items: center;
    height: 100%;
    background: url('@/assets/auth-bg.jpg') no-repeat center right;
    background-size: cover;

    .page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -8rem auto 0;
        max-width: 45rem;
    }

    .form-title {
        font-size: 2.8rem;
        margin-bottom: 2rem;
    }

    .form-description {
        margin-bottom: 5rem;
    }

    .sign-form {
        width: 100%;
    }

    .form-btn {
        margin-top: 3rem;
    }

    .secondary-link {
        text-decoration: none;
        margin-top: 2rem;

        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 991px), (max-height: 700px) {
    .full-screen-page {
        .page-content {
            margin: 0 auto;
        }
    }
}
</style>
