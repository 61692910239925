<template>
    <FullPageLayout>
        <h1 class="form-title text-h1 text-center">{{ $t('authPage.restoreTitle') }}</h1>

        <p v-if="!authStore.repeatRestoreRequestIn" class="form-description text-h2 text-center">
            {{ $t('authPage.restoreDescription') }}
        </p>

        <p v-else class="form-description text-h2 text-center">
            {{ $t('authPage.restoreInfo') }}
            <br />
            <span class="text-h3-bold">{{ authStore.repeatRestoreRequestIn }} {{ $t('authPage.seconds') }}</span>
        </p>

        <form class="sign-form" @submit.prevent="restorePass">
            <InputComponent
                v-model="restorePassForm.email"
                :new-style="true"
                :input-type="'email'"
                :input-label="'E-mail'"
                :input-placeholder="'E-mail'"
                :input-value="restorePassForm.email"
                :input-error="v$.restorePassForm.email.$errors"
            />

            <button :disabled="authStore.repeatRestoreRequestIn" class="form-btn btn-gradient" type="submit">
                {{ $t('authPage.resetPass') }}
            </button>
        </form>

        <router-link :to="{ name: 'login' }" class="secondary-link text-h2">{{ $t('authPage.enter') }}</router-link>
        <LoaderComponent v-if="authStore.loadingRestorePass" />
    </FullPageLayout>
</template>

<script>
import FullPageLayout from '@/layouts/FullPageLayout.vue'
import InputComponent from '@/components/general/InputComponent.vue'
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'
import { useAuthStore } from '@/stores/authStore'
import LoaderComponent from '@/components/general/LoaderComponent.vue'

export default {
    name: 'RestorePassPage',
    components: { LoaderComponent, InputComponent, FullPageLayout },
    setup() {
        const authStore = useAuthStore()

        return { authStore, v$: useVuelidate() }
    },
    data() {
        return {
            restorePassForm: {
                email: null,
            },
        }
    },
    validations() {
        return {
            restorePassForm: {
                email: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                    email: helpers.withMessage(this.$t('validation.email'), email),
                },
            },
        }
    },

    methods: {
        async restorePass() {
            const isFormCorrect = await this.v$.restorePassForm.$validate()

            if (isFormCorrect) {
                await this.authStore.restorePassword(this.restorePassForm)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.form-description {
    color: $grey;
}

.form-btn {
    margin-top: 0;
    max-width: 100%;

    &:disabled {
        pointer-events: none;
        background: grey;
        border-color: grey;
    }
}
</style>
