import { defineStore } from 'pinia'
import router from '@/router'
import fetchWrapper from '@/helpers/fetchWrapper'
import { socket, socketConnect, socketDisconnect } from '@/services/socket'
import { useChannelsStore } from '@/stores/channelsStore'
import { useChatsStore } from '@/stores/chatsStore'
import { useManagersStore } from '@/stores/managersStore'
import { useQuickRepliesStore } from '@/stores/quickRepliesStore'
import { useStatusesStore } from '@/stores/statusesStore'
import parseJwt from '@/helpers/parse-jwt'
import { createToaster } from '@meforma/vue-toaster'
import { useRoute } from 'vue-router'

const toaster = createToaster({ position: 'bottom' })
const baseRoute = '/user'

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        userInfo: {},
        userIsAdmin: false,
        refreshTokenTimeout: null,
        tokenExpireTime: null,
        loggedIn: false,
        returnUrl: null,
        repeatRestoreRequestIn: 0,
        loadingRestorePass: false,
    }),

    actions: {
        async login(loginObj) {
            fetchWrapper(`${baseRoute}/auth/login`, 'POST', loginObj).then((data) => {
                if (data.code === 200) {
                    this.onLogin(data.response)
                    router.push('/')
                } else {
                    toaster.error(data.response.error)
                }
            })
        },

        onLogin(response) {
            this.userInfo = parseJwt(response.token)
            this.loggedIn = true
            this.userIsAdmin = this.userInfo?.roles.includes('admin')
            localStorage.setItem('authStatus', JSON.stringify({ isAuthenticated: true, timestamp: Date.now() }))
            this.startRefreshTokenTimer()
            socketDisconnect()
            socketConnect()
        },

        async registration(registrationObj) {
            fetchWrapper(`${baseRoute}/auth/register`, 'POST', registrationObj).then((data) => {
                if (data.code === 200) {
                    toaster.success(this.$t('authPage.registrationToast'))
                    router.push('/login')
                } else {
                    toaster.error(data.response.error)
                }
            })
        },

        startRefreshTokenTimer() {
            this.stopRefreshTokenTimer()
            const expires = new Date(this.userInfo.exp * 1000)
            const timeout = expires.getTime() - Date.now() - 60 * 1000

            this.refreshTokenTimeout = setTimeout(() => this.refreshToken(false), timeout)
        },

        stopRefreshTokenTimer() {
            try {
                clearTimeout(this.refreshTokenTimeout)
            } catch (e) {
                console.error(e)
            }
        },

        async restorePassword(restoreObj) {
            this.loadingRestorePass = true

            fetchWrapper(`${baseRoute}/forgot-password`, 'POST', restoreObj)
                .then(() => {
                    this.repeatRestoreRequestIn = 120

                    const countdownInterval = setInterval(() => {
                        if (this.repeatRestoreRequestIn === 1) {
                            clearInterval(countdownInterval)
                        }

                        this.repeatRestoreRequestIn--
                    }, 1000)
                })
                .finally(() => {
                    this.loadingRestorePass = false
                })
        },

        async createNewPass(resetObj) {
            fetchWrapper(`${baseRoute}/reset-password`, 'POST', resetObj).then(() => {
                router.push(this.returnUrl || '/')
            })
        },

        async createManagerPass(token, password) {
            fetchWrapper(`${baseRoute}/approve-manager/${token}`, 'POST', { password }).then(() => {
                router.push(this.returnUrl || '/')
            })
        },

        async refreshToken(showErrors = true) {
            await fetchWrapper(`${baseRoute}/auth/refresh-token`, 'POST', {}, {}, showErrors)
                .then(({ response }) => {
                    this.onLogin(response)
                })
                .catch(() => {
                    this.logout()

                    return
                })

            if (this.returnUrl) {
                await router.push(this.returnUrl)
                this.returnUrl = null
            }
        },

        logout() {
            this.loggedIn = false
        },

        handleLogout() {
            fetchWrapper(`${baseRoute}/auth/logout`, 'POST', {}, {}, false)
            const route = useRoute()
            const disableLogoutOnRefresh =
                route?.name === 'createManagerPassword' ||
                route?.name === 'createNewPassword' ||
                route?.name === 'registration'

            if (!disableLogoutOnRefresh) {
                router.push('/login')
            }
            this.userInfo = {}
            this.stopRefreshTokenTimer()
            socket.io.off('reconnect')
            socket.disconnect()
            this.resetStores()
            localStorage.setItem('authStatus', JSON.stringify({ isAuthenticated: false, timestamp: Date.now() }))
        },

        resetStores() {
            useChannelsStore().$reset()
            useChatsStore().$reset()
            useManagersStore().$reset()
            useQuickRepliesStore().$reset()
            useStatusesStore().$reset()
        },
    },
})
