<template>
    <CardComponent>
        <template #header>
            <h2 class="card-title text-h1">{{ $t('managersPage.addNewManager') }}</h2>
        </template>

        <template #body>
            <div class="row">
                <div class="inputs-col col-lg-6">
                    <div class="inputs-group-title text-h3">{{ $t('managersPage.personalInfo') }}</div>
                    <InputComponent
                        v-model="managerForm.name"
                        :input-type="'text'"
                        :input-placeholder="$t('managersPage.name')"
                        :input-value="managerForm.name"
                        :input-error="v$.managerForm.name.$errors"
                    />

                    <InputComponent
                        v-model="managerForm.last_name"
                        :input-type="'text'"
                        :input-placeholder="$t('managersPage.surname')"
                        :input-value="managerForm.last_name"
                        :input-error="v$.managerForm.last_name.$errors"
                    />
                </div>

                <div class="inputs-col col-lg-6">
                    <div class="inputs-group-title text-h3">E-mail</div>

                    <InputComponent
                        v-model="managerForm.email"
                        :input-type="'email'"
                        :input-placeholder="'Email'"
                        :input-value="managerForm.email"
                        :input-error="v$.managerForm.email.$errors"
                    />
                </div>
            </div>
        </template>

        <template #footer>
            <router-link :to="{ name: 'managersList' }" class="btn-transparent">
                {{ $t('managersPage.cancel') }}
            </router-link>
            <button class="btn-black" @click="submitManagerForm">{{ $t('managersPage.save') }}</button>
        </template>
    </CardComponent>
</template>

<script>
import InputComponent from '@/components/general/InputComponent.vue'
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'
import { useManagersStore } from '@/stores/managersStore'
import CardComponent from '@/components/general/CardComponent.vue'

export default {
    name: 'ManagerFormPage',
    components: { CardComponent, InputComponent },
    setup() {
        const managersStore = useManagersStore()

        return { managersStore, v$: useVuelidate() }
    },
    data() {
        return {
            managerForm: {
                name: '',
                last_name: '',
                email: '',
            },
        }
    },
    validations() {
        return {
            managerForm: {
                name: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
                last_name: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
                email: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                    email: helpers.withMessage(this.$t('validation.email'), email),
                },
            },
        }
    },
    mounted() {
        document.addEventListener('keyup', this.onKeyUp)
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.onKeyUp)
    },
    methods: {
        onKeyUp(e) {
            if (e.key === 'Escape') {
                this.$router.push({ name: 'managersList' })
            }

            if (e.key === 'Enter') {
                this.submitManagerForm()
            }
        },
        async submitManagerForm() {
            const isFormCorrect = await this.v$.managerForm.$validate()

            if (isFormCorrect) {
                await this.managersStore
                    .addManager(this.managerForm)
                    .then(() => this.$router.push({ name: 'managersList' }))
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
