<template>
    <div class="registration-page">
        <div class="left-col">
            <div class="logo-wrap">
                <img src="@/assets/logo.svg" alt="" />
            </div>
            <div class="col-title text-center">{{ $t('authPage.regTitle') }}</div>
            <div class="col-description text-h2 text-center">{{ $t('authPage.regDescription') }}</div>
            <form class="registration-form" @submit.prevent="register">
                <div class="row">
                    <div class="col-lg-6">
                        <InputComponent
                            v-model="registrationForm.name"
                            :new-style="true"
                            :input-type="'text'"
                            :input-label="$t('authPage.name')"
                            :input-placeholder="$t('authPage.name')"
                            :input-value="registrationForm.name"
                            :input-error="v$.registrationForm.name.$errors"
                        />
                    </div>
                    <div class="col-lg-6">
                        <InputComponent
                            v-model="registrationForm.last_name"
                            :new-style="true"
                            :input-type="'text'"
                            :input-label="$t('authPage.surname')"
                            :input-placeholder="$t('authPage.surname')"
                            :input-value="registrationForm.last_name"
                            :input-error="v$.registrationForm.last_name.$errors"
                        />
                    </div>
                    <div class="col-12">
                        <InputComponent
                            v-model="registrationForm.email"
                            :new-style="true"
                            :input-type="'email'"
                            :input-label="'E-mail'"
                            :input-placeholder="'E-mail'"
                            :input-value="registrationForm.email"
                            :input-error="v$.registrationForm.email.$errors"
                        />

                        <InputComponent
                            v-model="registrationForm.workspace_name"
                            :new-style="true"
                            :input-type="'text'"
                            :input-label="$t('authPage.company')"
                            :input-placeholder="$t('authPage.company')"
                            :input-value="registrationForm.workspace_name"
                            :input-error="v$.registrationForm.workspace_name.$errors"
                        />

                        <InputComponent
                            v-model="registrationForm.password"
                            :new-style="true"
                            :input-type="'password'"
                            :password-toggler="true"
                            :input-label="$t('authPage.enterPass')"
                            :input-placeholder="$t('authPage.enterPass')"
                            :input-value="registrationForm.password"
                            :input-error="v$.registrationForm.password.$errors"
                        />

                        <InputComponent
                            v-model="repeatPassword"
                            :new-style="true"
                            :input-type="'password'"
                            :password-toggler="true"
                            :input-label="$t('authPage.repeatPass')"
                            :input-placeholder="$t('authPage.repeatPass')"
                            :input-value="repeatPassword"
                            :input-error="v$.repeatPassword.$errors"
                        />

                        <div class="form-bottom">
                            <div class="policy-submit">
                                <input id="policy-agree" v-model="agreedWithPolicy" type="checkbox" />
                                <label for="policy-agree" class="text-h5">{{ $t('authPage.agree') }}&nbsp;</label>
                            </div>
                            <a href="" class="text-h5">{{ $t('authPage.policy') }}</a>
                            <div class="policy-error">
                                {{ v$.agreedWithPolicy.$errors[0]?.$message }}
                            </div>
                        </div>

                        <button class="form-btn btn-gradient" type="submit">{{ $t('authPage.register') }}</button>
                    </div>
                </div>
            </form>

            <div class="bottom-links">
                <span class="text-h2">{{ $t('authPage.haveAccount') }}&nbsp;</span>
                <router-link :to="{ name: 'login' }" class="secondary-link text-h2">
                    {{ $t('authPage.enter') }}
                </router-link>
            </div>
        </div>
        <div class="right-col">
            <div class="col-title text-center">
                {{ $t('authPage.regInfo') }}
            </div>
            <img src="@/assets/registration-img.png" class="registration-img" alt="" />
        </div>
    </div>
</template>

<script>
import InputComponent from '@/components/general/InputComponent.vue'
import { useAuthStore } from '@/stores/authStore'
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required, sameAs } from '@vuelidate/validators'

export default {
    name: 'RegistrationPage',
    components: { InputComponent },
    setup() {
        const authStore = useAuthStore()

        return { authStore, v$: useVuelidate() }
    },
    validations() {
        return {
            registrationForm: {
                name: { required: helpers.withMessage(this.$t('validation.required'), required) },
                last_name: { required: helpers.withMessage(this.$t('validation.required'), required) },
                email: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                    email: helpers.withMessage(this.$t('validation.email'), email),
                },
                workspace_name: { required: helpers.withMessage(this.$t('validation.required'), required) },
                password: { required: helpers.withMessage(this.$t('validation.required'), required) },
            },
            repeatPassword: {
                required: helpers.withMessage(this.$t('validation.required'), required),
                sameAs: helpers.withMessage(
                    this.$t('validation.passwordMatch'),
                    sameAs(this.registrationForm.password)
                ),
            },
            agreedWithPolicy: {
                sameAs: helpers.withMessage(this.$t('validation.agree'), sameAs(true)),
            },
        }
    },
    data() {
        return {
            registrationForm: {
                name: null,
                last_name: null,
                email: null,
                workspace_name: null,
                password: null,
            },
            repeatPassword: null,
            agreedWithPolicy: false,
        }
    },
    methods: {
        async register() {
            const isFormCorrect = await this.v$.$validate()

            if (isFormCorrect) {
                await this.authStore.registration(this.registrationForm)
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';

.registration-page {
    display: flex;
    align-items: center;
    height: 100%;
    background: url('@/assets/auth-bg.jpg') no-repeat center left;
    background-size: cover;
}

.left-col {
    max-height: 100%;
    width: 50%;
    padding: 5rem 1rem;
    overflow-y: auto;
    overflow-x: hidden;

    .logo-wrap {
        width: 14.1rem;
        margin: 0 auto 5rem;
    }

    .col-title {
        font-size: 2.8rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .col-description {
        margin-bottom: 5rem;
    }

    .registration-form {
        max-width: 50rem;
        margin: 0 auto;

        .form-btn {
            max-width: 100%;
        }

        .form-bottom {
            position: relative;
            display: flex;
            align-items: center;
            margin: 3rem 0;

            .policy-error {
                position: absolute;
                top: 1.5rem;
                left: 2.2rem;
                font-size: 1rem;
                line-height: 120%;
                font-weight: 500;
                color: #dc0000;
            }
        }

        .policy-submit {
            display: flex;
            align-items: center;

            input {
                display: none;
            }

            input:checked + label {
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 2.2rem;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background: #620475;
                    transform: translateY(-50%) translateX(0.3rem);
                }
            }

            label {
                position: relative;
                padding-left: 4.8rem;
                cursor: pointer;
                user-select: none;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 2.2rem;
                    width: 1.6rem;
                    height: 1.6rem;
                    border-radius: 50%;
                    border: 2px solid #620475;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .bottom-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;

        .secondary-link {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.right-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: $grey_light;
    width: 50%;
    height: 100%;

    .col-title {
        padding: 0 1.5rem;
        max-width: 60rem;
        margin: 0 auto 6.5rem;
        font-size: 2.2rem;
        font-weight: 700;
    }
}

@media screen and (max-width: 1199px) {
    .left-col {
        width: 100%;
    }

    .right-col {
        display: none;
    }
}
</style>
