<template>
    <audio ref="audioMessage" class="audio-message" controls>
        <source :src="messageData.attachments[0].url" type="audio/wav" />
    </audio>
</template>

<script>
export default {
    name: 'AudioMessage',
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        firstAttachmentUrl() {
            return this.messageData.attachments.length > 0 ? this.messageData.attachments[0].url : null
        },
    },
    watch: {
        firstAttachmentUrl() {
            this.$refs.audioMessage.load()
        },
    },
}
</script>

<style lang="scss">
@media screen and (max-width: 575px) {
    .audio-message {
        max-width: 28rem;
        width: 28rem;
    }
}
</style>
