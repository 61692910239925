<template>
    <CardComponent>
        <template #header>
            <h2 class="card-title text-h1">
                {{ title }}
            </h2>
        </template>

        <template #body>
            <div class="row">
                <div class="inputs-col col-lg-6">
                    <div class="inputs-group-title text-h3">{{ $t('repliesPage.shortName') }}</div>
                    <InputComponent
                        v-model="quickReplyForm.name"
                        :input-type="'text'"
                        :input-placeholder="$t('repliesPage.shortNamePlaceholder')"
                        :input-value="quickReplyForm.name"
                        :input-error="v$.quickReplyForm.name.$errors"
                    />
                </div>

                <div class="inputs-col col-lg-6">
                    <div class="inputs-group-title text-h3">{{ $t('repliesPage.longName') }}</div>
                    <InputComponent
                        v-model="quickReplyForm.text"
                        :input-type="'text'"
                        :input-placeholder="$t('repliesPage.longNamePlaceholder')"
                        :input-value="quickReplyForm.text"
                        :input-error="v$.quickReplyForm.text.$errors"
                    />
                </div>
            </div>
        </template>

        <template #footer>
            <router-link :to="{ name: 'repliesList' }" class="btn-transparent">
                {{ $t('repliesPage.cancel') }}
            </router-link>
            <button class="btn-black" @click="submitQuickReplyForm">{{ $t('repliesPage.save') }}</button>
        </template>
    </CardComponent>
</template>

<script>
import InputComponent from '@/components/general/InputComponent.vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { useQuickRepliesStore } from '@/stores/quickRepliesStore'
import CardComponent from '@/components/general/CardComponent.vue'

export default {
    name: 'QuickReplyForm',
    components: { CardComponent, InputComponent },
    props: {
        editForm: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const quickRepliesStore = useQuickRepliesStore()

        return { quickRepliesStore, v$: useVuelidate() }
    },
    data() {
        return {
            title: '',
            quickReplyForm: {
                name: '',
                text: '',
            },
        }
    },
    validations() {
        return {
            quickReplyForm: {
                name: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
                text: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
            },
        }
    },
    created() {
        if (this.editForm) {
            if (Object.keys(this.quickRepliesStore.quickReplyEdit).length) {
                this.title = this.$t('repliesPage.editReply')
            } else {
                this.$router.push({ name: 'repliesList' })
            }
        } else {
            this.title = this.$t('repliesPage.addReply')
        }
    },
    mounted() {
        document.addEventListener('keyup', this.onKeyUp)

        if (Object.keys(this.quickRepliesStore.quickReplyEdit).length) {
            this.quickReplyForm = { ...this.quickRepliesStore.quickReplyEdit }
        }
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.onKeyUp)
        this.quickRepliesStore.quickReplyEdit = {}
    },
    methods: {
        onKeyUp(e) {
            if (e.key === 'Escape') {
                this.$router.push({ name: 'repliesList' })
            }

            if (e.key === 'Enter') {
                this.submitQuickReplyForm()
            }
        },

        async submitQuickReplyForm() {
            const isFormCorrect = await this.v$.quickReplyForm.$validate()

            if (!isFormCorrect) {
                return
            }

            await this.quickReplyCreateOrUpdate(!this.editForm)
            this.$router.push({ name: 'repliesList' })
        },

        async quickReplyCreateOrUpdate(flag) {
            return flag
                ? this.quickRepliesStore.addQuickReply(this.quickReplyForm)
                : this.quickRepliesStore.editQuickReply(this.quickReplyForm)
        },
    },
}
</script>

<style lang="scss" scoped></style>
