<template>
    <div class="reply-message-wrapper">
        <div class="story-origin text-h4 text-grey">{{ $t('components.replyOnMessage') }}</div>
        <div class="message-body">
            <div class="divider"></div>
            <component :is="componentName[messageData.type]" :message-data="messageData" :reply-message="true" />
        </div>
    </div>
</template>

<script>
import TextType from '@/views/chats/messages/message-types/TextType.vue'
import ReplyType from '@/views/chats/messages/message-types/ReplyType.vue'
import AttachmentType from '@/views/chats/messages/message-types/AttachmentType.vue'
import UnsupportedType from '@/views/chats/messages/message-types/UnsupportedType.vue'
import DeletedType from '@/views/chats/messages/message-types/DeletedType.vue'

export default {
    name: 'ReplyMessageWrapper',
    components: {
        TextType,
        ReplyType,
        AttachmentType,
        UnsupportedType,
        DeletedType,
    },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            componentName: {
                text: 'TextType',
                attachment: 'AttachmentType',
                unsupported: 'UnsupportedType',
                reply: 'TextType',
                deleted: 'DeletedType',
            },
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.story-origin {
    margin-bottom: 0.6rem;
}

.message-body {
    display: flex;
    max-width: 100%;
}

.divider {
    display: flex;
    flex-direction: column-reverse;
    width: 0.4rem;
    background: rgba($grey, 0.5);
    border-radius: 0.8rem;
    margin-right: 1rem;
}
</style>
