export const formatDate = (dateString) => {
    const timestamp = parseInt(dateString)
    const date = new Date(timestamp)
    const now = new Date()

    // Is today?
    if (date.toDateString() === now.toDateString()) {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }

    // Is yesterday?
    const yesterday = new Date(now)

    yesterday.setDate(yesterday.getDate() - 1)
    if (date.toDateString() === yesterday.toDateString()) {
        return 'Вчора'
    }

    // Is the same year?
    if (date.getFullYear() === now.getFullYear()) {
        return date.toLocaleDateString([], { day: '2-digit', month: '2-digit' })
    }

    // Older
    return date.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: '2-digit' })
}
