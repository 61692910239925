<template>
    <div class="message-story-reply">
        <div class="story-origin text-h4 text-grey">{{ $t('components.mentionInStory') }}</div>
        <div class="story-content">
            <div class="divider"></div>
            <div class="content-item">
                <template v-if="messageData.attachments.mime_type !== 'video'">
                    <img class="img-preview" :src="messageData.attachments.url" alt="" @click="showPopup = true" />

                    <transition name="popup-fade">
                        <popup-component
                            v-if="showPopup"
                            :hide-loader="!mediaLoaded && showPopup"
                            @close-popup="hidePopup"
                        >
                            <img
                                v-show="mediaLoaded"
                                class="popup-media"
                                :src="messageData.attachments.url"
                                alt=""
                                @load="onMediaLoaded"
                            />
                        </popup-component>
                    </transition>
                </template>

                <template v-else>
                    <video class="video-preview" @click="showPopup = true">
                        <source :src="messageData.attachments.url" type="video/mp4" />
                    </video>
                    <img class="video-play-btn" src="@/assets/icons/video-play-icon.svg" alt="" />

                    <transition name="popup-fade">
                        <popup-component v-if="showPopup" :hide-loader="false" @close-popup="hidePopup">
                            <video v-show="true" autoplay controls class="popup-media">
                                <source :src="messageData.attachments.url" type="video/mp4" />
                            </video>
                        </popup-component>
                    </transition>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PopupComponent from '@/components/general/PopupComponent.vue'

export default {
    name: 'StoryMentionMessage',
    components: { PopupComponent },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            showPopup: false,
            mediaLoaded: false,
        }
    },
    methods: {
        onMediaLoaded() {
            this.mediaLoaded = true
        },

        hidePopup() {
            this.mediaLoaded = false
            this.showPopup = false
        },
    },
}
</script>

<style lang="scss">
@import '@/scss/common/colors';

.story-origin {
    margin-bottom: 0.6rem;
}

.story-content {
    display: flex;
    margin-bottom: 0.6rem;
}

.divider {
    display: flex;
    flex-direction: column-reverse;
    width: 0.4rem;
    background: rgba($grey, 0.5);
    border-radius: 0.8rem;
    margin-right: 1rem;
}

.content-item {
    height: 20rem;
    border-radius: 0.8rem;
    overflow: hidden;
    width: fit-content;
    position: relative;

    &:hover {
        cursor: pointer;

        .img-preview,
        .video-preview {
            filter: brightness(0.8);
        }
    }

    .img-preview {
        height: 20rem;
    }

    .video-preview {
        position: relative;
        height: 20rem;
        max-width: 100%;
        background: black;
    }

    .video-play-btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 3rem;
        height: 3rem;
        pointer-events: none;
    }
}
</style>
