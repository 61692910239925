import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import LoginPage from '@/views/auth/LoginPage.vue'
import RestorePassPage from '@/views/auth/RestorePassPage.vue'
import NewPassPage from '@/views/auth/NewPassPage.vue'
import ChatsPage from '@/views/chats/ChatsPage.vue'
import AccountPage from '@/views/AccountPage.vue'
import { useAuthStore } from '@/stores/authStore'
import ChannelsPage from '@/views/ChannelsPage.vue'
import ManagersPage from '@/views/managers/ManagersPage.vue'
import StatusesPage from '@/views/StatusesPage.vue'
import errorPage from '@/views/ErrorPage.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import ManagersList from '@/views/managers/ManagersList.vue'
import ManagerForm from '@/views/managers/ManagerForm.vue'
import QuickRepliesPage from '@/views/quick-replies/QuickRepliesPage.vue'
import QuickRepliesList from '@/views/quick-replies/QuickRepliesList.vue'
import QuickReplyForm from '@/views/quick-replies/QuickReplyForm.vue'
import ChatConversation from '@/views/chats/ChatConversation.vue'
import RegistrationPage from '@/views/auth/RegistrationPage.vue'

const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                name: 'home',
                path: '/',
                component: HomePage,
            },
            {
                name: 'chats',
                path: '/chats',
                component: ChatsPage,
                children: [
                    {
                        name: 'chatContent',
                        path: '/chats/:conversation_id',
                        component: ChatConversation,
                    },
                ],
            },
            {
                name: 'account',
                path: '/account',
                component: AccountPage,
            },
            {
                name: 'managers',
                path: '/managers',
                component: ManagersPage,
                children: [
                    {
                        name: 'managersList',
                        path: '',
                        component: ManagersList,
                    },
                    {
                        name: 'createManager',
                        path: 'create',
                        component: ManagerForm,
                    },
                ],
            },
            {
                name: 'statuses',
                path: '/statuses',
                component: StatusesPage,
            },
            {
                name: 'quickReplies',
                path: '/quick-replies',
                component: QuickRepliesPage,
                children: [
                    {
                        name: 'repliesList',
                        path: '',
                        component: QuickRepliesList,
                    },
                    {
                        name: 'createReply',
                        path: 'create',
                        component: QuickReplyForm,
                    },
                    {
                        name: 'editReply',
                        path: 'edit',
                        component: QuickReplyForm,
                        props: { editForm: true },
                    },
                ],
            },
            {
                name: 'channels',
                path: '/channels',
                component: ChannelsPage,
            },
        ],
    },
    {
        name: 'login',
        path: '/login',
        component: LoginPage,
    },
    {
        name: 'registration',
        path: '/registration',
        component: RegistrationPage,
    },
    {
        name: 'restorePassword',
        path: '/restore-password',
        component: RestorePassPage,
    },
    {
        name: 'createManagerPassword',
        path: '/create-manager-password/:token',
        component: NewPassPage,
        props: { createManager: true },
    },
    {
        name: 'createNewPassword',
        path: '/create-new-password/:token',
        component: NewPassPage,
    },
    {
        name: '404',
        path: '/404',
        component: errorPage,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to) => {
    const authStore = useAuthStore()
    const publicPages = [
        '/login',
        '/create-manager-password',
        '/create-new-password',
        '/restore-password',
        '/registration',
    ]
    const adminPages = ['/managers', '/statuses', '/channels']
    const authRequired = !publicPages.some((page) => to.path.startsWith(page))
    const adminRequired = adminPages.some((page) => to.path.startsWith(page))

    if (authRequired && !authStore.loggedIn) {
        authStore.returnUrl = to.path

        return '/login'
    }

    if (!authRequired && authStore.loggedIn) {
        return '/'
    }

    if (adminRequired && !authStore.userIsAdmin) {
        return '/'
    }
})

export default router
