<template>
    <FullPageLayout>
        <div class="logo-wrap img-wrap">
            <img src="@/assets/logo.svg" alt="" />
        </div>
        <form class="sign-form" @submit.prevent="login">
            <InputComponent
                v-model="loginForm.email"
                :new-style="true"
                :input-type="'email'"
                :input-label="'E-mail'"
                :input-placeholder="'E-mail'"
                :input-value="loginForm.email"
                :input-error="v$.loginForm.email.$errors"
            />

            <InputComponent
                v-model="loginForm.password"
                :new-style="true"
                :input-type="'password'"
                :input-label="$t('authPage.password')"
                :input-placeholder="$t('authPage.password')"
                :input-value="loginForm.password"
                :input-error="v$.loginForm.password.$errors"
            />

            <button class="form-btn btn-gradient" type="submit">{{ $t('authPage.login') }}</button>
        </form>

        <div class="bottom-links">
            <router-link :to="{ name: 'registration' }" class="secondary-link text-h2">
                {{ $t('authPage.noAccount') }}
            </router-link>
            <router-link :to="{ name: 'restorePassword' }" class="secondary-link text-h2">
                {{ $t('authPage.forgotPass') }}
            </router-link>
        </div>
    </FullPageLayout>
</template>

<script>
import FullPageLayout from '@/layouts/FullPageLayout.vue'
import { useAuthStore } from '@/stores/authStore'
import InputComponent from '@/components/general/InputComponent.vue'
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'

export default {
    name: 'LoginPage',
    components: { InputComponent, FullPageLayout },
    setup() {
        const authStore = useAuthStore()

        return { authStore, v$: useVuelidate() }
    },
    data() {
        return {
            loginForm: {
                email: null,
                password: null,
            },
        }
    },
    validations() {
        return {
            loginForm: {
                email: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                    email: helpers.withMessage(this.$t('validation.email'), email),
                },
                password: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
            },
        }
    },
    methods: {
        async login() {
            const isFormCorrect = await this.v$.loginForm.$validate()

            if (isFormCorrect) {
                await this.authStore.login(this.loginForm)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.logo-wrap {
    width: 37rem;
    height: 8.3rem;
    margin-bottom: 5rem;
}

.form-btn {
    max-width: 100%;
}

.bottom-links {
    margin-top: 2rem;
}

@media screen and (max-width: 767px) {
    .logo-wrap {
        width: 27.75rem;
        height: 6.225rem;
        margin-bottom: 3rem;
    }
}
</style>
