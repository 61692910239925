<template>
    <div>
        <div class="message-image">
            <img :src="messageData.attachments[0]?.url" alt="" @click="showPopup = true" />
        </div>
        <transition name="popup-fade">
            <popup-component v-if="showPopup" :hide-loader="!mediaLoaded && showPopup" @close-popup="hidePopup">
                <img
                    v-show="mediaLoaded"
                    class="popup-media"
                    :src="messageData.attachments[0]?.url"
                    alt=""
                    @load="onMediaLoaded"
                />
            </popup-component>
        </transition>
    </div>
</template>

<script>
import PopupComponent from '@/components/general/PopupComponent.vue'

export default {
    name: 'ImageMessage',
    components: { PopupComponent },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            showPopup: false,
            mediaLoaded: false,
        }
    },
    methods: {
        onMediaLoaded() {
            this.mediaLoaded = true
        },

        hidePopup() {
            this.mediaLoaded = false
            this.showPopup = false
        },
    },
}
</script>

<style lang="scss" scoped>
.message-image {
    position: relative;
    display: flex;
    height: 15rem;
    width: 15rem;
    border-radius: 0.8rem;
    overflow: hidden;

    &:hover {
        cursor: pointer;

        img {
            filter: brightness(0.8);
        }
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
    }
}

.popup-media {
    max-width: 100%;
    max-height: 100%;
}
</style>
