import axios from 'axios'
import { createToaster } from '@meforma/vue-toaster'
import { useAuthStore } from '@/stores/authStore'

const toaster = createToaster({ position: 'bottom' })

axios.defaults.baseURL = process.env.VUE_APP_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true

export default async function fetchWrapper(url, method = 'GET', data = {}, customHeaders = {}, showErrors = true) {
    const config = {
        url,
        method,
        headers: { ...authHeader(), ...customHeaders },
    }

    if (method === 'GET') {
        config.params = data
    } else {
        config.data = data
    }

    try {
        const response = await axios(config)

        if (response.data.code === 401) {
            const authStore = useAuthStore()

            authStore.logout()
        } else if (response.data.code !== 200) {
            throw { response: response, isApiError: true }
        }

        return response.data
    } catch (error) {
        handleError(error, showErrors)
    }
}

function authHeader() {
    const authStore = useAuthStore()

    if (authStore.loggedIn) {
        return { Authorization: 'Bearer ' + authStore.userInfo?.access_token }
    }

    return {}
}

function handleError(error, showErrors) {
    let errorMessage = 'An error occurred'

    if (error.isApiError) {
        const response = error.response.data

        errorMessage = response.response?.error || 'Unknown API error'
    } else if (error.message) {
        errorMessage = error.message
    }

    if (showErrors) {
        toaster.error(errorMessage)
    }

    throw new Error(errorMessage)
}
