import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetchWrapper'
import { createToaster } from '@meforma/vue-toaster'
import i18n from '@/i18n'

const toaster = createToaster({ position: 'bottom' })
const baseRoute = '/channels'

export const useChannelsStore = defineStore('channelsStore', {
    state: () => ({
        channelsList: [],
        loadingChannels: false,
    }),
    actions: {
        async loadChannels() {
            this.loadingChannels = true

            return await fetchWrapper(`${baseRoute}/get-list`, 'POST')
                .then((response) => {
                    const { response: channelsResponse } = response

                    this.channelsList = channelsResponse.channels

                    return channelsResponse.channels
                })
                .finally(() => {
                    this.loadingChannels = false
                })
        },

        async deleteChannel(id) {
            this.loadingChannels = true

            await fetchWrapper(`${baseRoute}/delete`, 'DELETE', { id }).then(() => {
                toaster.success(i18n.global.t('channelsPage.deletedToast'))
            })
            await this.loadChannels()
        },

        async addChannel(data) {
            this.loadingChannels = true

            await fetchWrapper(`${baseRoute}/add`, 'POST', data).then(() => {
                toaster.success(i18n.global.t('channelsPage.addedToast'))
            })
            await this.loadChannels()
        },
    },
})
