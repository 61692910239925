<template>
    <div class="managers-page">
        <CardComponent>
            <template #header>
                <h2 class="card-title text-h1">{{ $t('managersPage.managers') }}</h2>
            </template>
            <template #body>
                <div class="list">
                    <div v-for="(item, index) in managersStore.managersList" :key="index" class="list-item">
                        <div class="item-info">
                            <div class="text-h2-bold">
                                {{ item.name + ' ' + item.last_name }}
                            </div>
                            <div class="text-h3 text-grey">
                                {{ item.email }}
                            </div>
                        </div>
                        <div v-if="!isAdmin(item)" class="item-buttons">
                            <div class="delete-btn item-btn" @click="confirmDelete(item.email)">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.375 25C8.75625 25 8.22675 24.7826 7.7865 24.3478C7.3455 23.9122 7.125 23.3889 7.125 22.7778V8.33333C6.80625 8.33333 6.53888 8.22704 6.32288 8.01444C6.10763 7.80111 6 7.53704 6 7.22222C6 6.90741 6.10763 6.64333 6.32288 6.43C6.53888 6.21741 6.80625 6.11111 7.125 6.11111H11.625C11.625 5.7963 11.733 5.53222 11.949 5.31889C12.1642 5.1063 12.4313 5 12.75 5H17.25C17.5688 5 17.8361 5.1063 18.0521 5.31889C18.2674 5.53222 18.375 5.7963 18.375 6.11111H22.875C23.1938 6.11111 23.4608 6.21741 23.676 6.43C23.892 6.64333 24 6.90741 24 7.22222C24 7.53704 23.892 7.80111 23.676 8.01444C23.4608 8.22704 23.1938 8.33333 22.875 8.33333V22.7778C22.875 23.3889 22.6549 23.9122 22.2146 24.3478C21.7736 24.7826 21.2437 25 20.625 25H9.375ZM9.375 8.33333V22.7778H20.625V8.33333H9.375ZM11.625 19.4444C11.625 19.7593 11.733 20.023 11.949 20.2356C12.1642 20.4489 12.4313 20.5556 12.75 20.5556C13.0687 20.5556 13.3361 20.4489 13.5521 20.2356C13.7674 20.023 13.875 19.7593 13.875 19.4444V11.6667C13.875 11.3519 13.7674 11.0878 13.5521 10.8744C13.3361 10.6619 13.0687 10.5556 12.75 10.5556C12.4313 10.5556 12.1642 10.6619 11.949 10.8744C11.733 11.0878 11.625 11.3519 11.625 11.6667V19.4444ZM16.125 19.4444C16.125 19.7593 16.233 20.023 16.449 20.2356C16.6643 20.4489 16.9312 20.5556 17.25 20.5556C17.5688 20.5556 17.8361 20.4489 18.0521 20.2356C18.2674 20.023 18.375 19.7593 18.375 19.4444V11.6667C18.375 11.3519 18.2674 11.0878 18.0521 10.8744C17.8361 10.6619 17.5688 10.5556 17.25 10.5556C16.9312 10.5556 16.6643 10.6619 16.449 10.8744C16.233 11.0878 16.125 11.3519 16.125 11.6667V19.4444Z"
                                        fill="#0081DC"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <infinite-loading target=".list" :distance="10" @infinite="infiniteHandler">
                        <template #complete>
                            <div class="text-h3 text-center no-results">{{ $t('managersPage.managersLoaded') }}</div>
                        </template>
                    </infinite-loading>
                </div>
            </template>
            <template #footer>
                <router-link :to="{ name: 'createManager' }" class="btn-transparent">
                    + {{ $t('managersPage.addManager') }}</router-link
                >
            </template>

            <template #loader>
                <LoaderComponent v-if="managersStore.loadingManagers" />
            </template>
        </CardComponent>
        <DeleteComponent
            v-if="showDeleteConfirmation"
            :item-id="itemId"
            :confirmation-title="$t('managersPage.manager')"
            @hide-confirmation="hideConfirmation"
            @delete-item="deleteManager"
        />
    </div>
</template>

<script>
import DeleteComponent from '@/components/general/DeleteComponent.vue'
import { useManagersStore } from '@/stores/managersStore'
import CardComponent from '@/components/general/CardComponent.vue'
import LoaderComponent from '@/components/general/LoaderComponent.vue'

export default {
    name: 'ManagersPage',
    components: { LoaderComponent, CardComponent, DeleteComponent },
    setup() {
        const managersStore = useManagersStore()

        return { managersStore }
    },
    data() {
        return {
            showDeleteConfirmation: false,
            itemId: null,
        }
    },
    methods: {
        confirmDelete(email) {
            this.itemId = email
            this.showDeleteConfirmation = true
        },

        hideConfirmation() {
            this.showDeleteConfirmation = false
            this.itemId = null
        },

        deleteManager(email) {
            this.managersStore.deleteManager(email)
        },

        async infiniteHandler($state) {
            const response = await this.managersStore.loadManagers()

            if (response.length === 10) {
                $state.loaded()
            } else {
                $state.complete()
            }
        },

        isAdmin(item) {
            return item.roles.includes('admin')
        },
    },
}
</script>

<style lang="scss" scoped>
.managers-page {
    width: 100%;
    height: 100%;
}
</style>
