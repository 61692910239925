<template>
    <div class="delete-modal">
        <div v-click-outside="hideConfirmation" class="delete-confirmation">
            <div class="confirmation-title text-h2-bold text-center">
                {{ $t('components.delete') }} {{ confirmationTitle }}?
            </div>

            <div class="confirmation-description text-h3 text-center text-grey">
                {{ confirmationDescription }}
            </div>

            <div class="confirmation-buttons">
                <button class="cancel-delete-btn text-h2 text-grey" @click="hideConfirmation">
                    {{ $t('components.cancel') }}
                </button>
                <button class="submit-delete-btn btn-transparent" @click="deleteItem">
                    {{ $t('components.delete') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
    name: 'DeleteComponent',
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        confirmationTitle: {
            type: String,
            required: true,
        },
        confirmationDescription: {
            type: String,
            default: 'Цю дію відмінити неможливо',
        },
        itemId: {
            type: [String, Number, Object],
            required: true,
        },
    },
    emits: ['deleteItem', 'hideConfirmation'],
    mounted() {
        document.addEventListener('keyup', this.onKeyUp)
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.onKeyUp)
    },
    methods: {
        onKeyUp(e) {
            if (e.key === 'Escape') {
                this.hideConfirmation()
            }

            if (e.key === 'Enter') {
                this.deleteItem()
            }
        },
        deleteItem() {
            this.$emit('deleteItem', this.itemId)
            this.hideConfirmation()
        },
        hideConfirmation() {
            this.$emit('hideConfirmation')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.delete-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba($black, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-confirmation {
    padding: 3rem;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    z-index: 100;
    min-width: 27.5rem;
}

.confirmation-description {
    margin-bottom: 2rem;
}

.confirmation-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
</style>
