import { reactive } from 'vue'
import { io } from 'socket.io-client'
import { useAuthStore } from '@/stores/authStore'

export const state = reactive({
    connected: true,
})

const URL = process.env.VUE_APP_SOCKET_URL

export const socket = io(URL, {
    auth: (cb) => {
        cb({
            token: useAuthStore()?.userInfo?.access_token,
        })
    },
    autoConnect: false,
})

export const socketConnect = () => {
    socket.auth.token = useAuthStore()?.userInfo?.access_token
    socket.connect((err) => {
        if (err) {
            state.connected = false
        }
    })
}

export const socketDisconnect = () => {
    socket.disconnect()
}

socket.on('connect', () => {
    state.connected = true
})

socket.on('disconnect', () => {
    state.connected = false
})
