import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetchWrapper'
import { createToaster } from '@meforma/vue-toaster'
import i18n from '@/i18n'

const toaster = createToaster({ position: 'bottom' })
const baseRoute = '/statuses'

export const useStatusesStore = defineStore('statusesStore', {
    state: () => ({
        statusesList: [],
        statusEdit: {},
        loadingStatuses: false,
    }),
    getters: {
        nonSystemStatusesList: (state) => {
            return state.statusesList.filter((status) => {
                return status?.is_system !== true
            })
        },
    },
    actions: {
        async loadStatuses() {
            this.loadingStatuses = true

            await fetchWrapper(`${baseRoute}/get-list`, 'POST')
                .then((response) => {
                    const { response: statusesResponse } = response

                    this.statusesList = statusesResponse.statuses
                })
                .finally(() => {
                    this.loadingStatuses = false
                })
        },

        async deleteStatus(id) {
            await fetchWrapper(`${baseRoute}/delete`, 'DELETE', { id }).then(() => {
                toaster.success(i18n.global.t('statusesPage.deletedToast'))
            })
            this.statusesList = this.statusesList.filter((object) => {
                return object.id !== id
            })
        },

        async addStatus(data) {
            await fetchWrapper(`${baseRoute}/add`, 'POST', { name: data }).then(() => {
                toaster.success(i18n.global.t('statusesPage.addedToast'))
            })
            await this.loadStatuses()
        },

        async editStatus(data) {
            await fetchWrapper(`${baseRoute}/update`, 'PUT', data).then(() => {
                toaster.success(i18n.global.t('statusesPage.editedToast'))
            })
        },

        async sortStatuses(id, newOrder) {
            await fetchWrapper(`${baseRoute}/sort`, 'PATCH', {
                id,
                sort_order: newOrder,
            }).then(() => {
                toaster.success(i18n.global.t('statusesPage.sortedToast'))
            })
        },

        setActiveStatuses(statuses) {
            for (let i = 0; i < this.statusesList.length; i++) {
                if (statuses.includes(this.statusesList[i].id)) {
                    this.statusesList[i].active = true
                }
            }
        },
    },
})
