<template>
    <nav v-touch:swipe.bottom="hideNavbar" class="navbar" :class="{ opened: navbarOpened }">
        <div class="nav-close-btn" @click="hideNavbar">
            <svg width="52" height="6" viewBox="0 0 52 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.15799 0.0125549C0.612638 -0.0747008 0.0998107 0.296657 0.0125549 0.842005C-0.0747008 1.38735 0.296657 1.90018 0.842005 1.98744L25.819 5.98376C25.878 5.99461 25.9385 6.00022 26 6.00014C26.0615 6.00022 26.122 5.99461 26.181 5.98376L51.158 1.98744C51.7033 1.90018 52.0747 1.38735 51.9874 0.842005C51.9002 0.296657 51.3874 -0.0747008 50.842 0.0125549L26 3.98728L1.15799 0.0125549Z"
                    fill="#96A1AB"
                />
            </svg>
        </div>
        <div class="navbar-top">
            <router-link :to="{ name: 'home' }" class="logo-link">
                <div class="logo-wrap img-wrap">
                    <img src="@/assets/logo.svg" alt="" />
                </div>
            </router-link>

            <router-link :to="{ name: 'chats' }" class="nav-link">
                <img src="@/assets/icons/chats-icon.svg" alt="" />
                <span> {{ $t('menu.chats') }} </span>
            </router-link>

            <router-link :to="{ name: 'account' }" class="nav-link">
                <img src="@/assets/icons/account-icon.svg" alt="" />
                <span> {{ $t('menu.account') }} </span>
            </router-link>

            <router-link v-if="authStore.userIsAdmin" :to="{ name: 'managersList' }" class="nav-link">
                <img src="@/assets/icons/managers-icon.svg" alt="" />
                <span> {{ $t('menu.managers') }} </span>
            </router-link>

            <router-link v-if="authStore.userIsAdmin" :to="{ name: 'statuses' }" class="nav-link">
                <img src="@/assets/icons/statuses-icon.svg" alt="" />
                <span> {{ $t('menu.statuses') }} </span>
            </router-link>

            <router-link :to="{ name: 'repliesList' }" class="nav-link">
                <img src="@/assets/icons/quick-answers-icon.svg" alt="" />
                <span> {{ $t('menu.replies') }} </span>
            </router-link>

            <router-link v-if="authStore.userIsAdmin" :to="{ name: 'channels' }" class="nav-link">
                <img src="@/assets/icons/communication-chanels-icon.svg" alt="" />
                <span> {{ $t('menu.channels') }} </span>
            </router-link>
        </div>
        <div class="navbar-bottom">
            <div class="locales-wrap">
                <button class="locale-btn text-h4" :class="{ active: $i18n.locale === 'ua' }" @click="setLocale('ua')">
                    UA
                </button>
                <button class="locale-btn text-h4" :class="{ active: $i18n.locale === 'en' }" @click="setLocale('en')">
                    EN
                </button>
            </div>
            <button class="logout-btn nav-link" @click="logout">
                <img src="@/assets/icons/exit-icon.svg" alt="" />
                <span> {{ $t('menu.logout') }} </span>
            </button>
        </div>
    </nav>
    <transition>
        <div v-if="navbarOpened" class="nav-bg" @click.prevent="hideNavbar"></div>
    </transition>
</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import i18n from '@/i18n'

export default {
    name: 'NavbarComponent',
    props: {
        navbarOpened: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['hide-navbar'],
    setup() {
        const authStore = useAuthStore()

        return { authStore }
    },
    computed: {
        i18n() {
            return i18n
        },
    },
    methods: {
        hideNavbar() {
            this.$emit('hide-navbar')
        },
        setLocale(locale) {
            this.$i18n.locale = locale
            localStorage.setItem('locale', locale)
        },
        logout() {
            this.authStore.logout()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/_colors.scss';

.v-enter-active,
.v-leave-active {
    transition: opacity ease-in-out 0.3s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 5rem 3rem 6rem;
    background: $grey-light;
    max-width: 31.5rem;
    width: 100%;
    overflow-y: auto;
    transition: all ease-in-out 0.3s;
}

.nav-close-btn {
    display: none;
}

.logo-link {
    display: flex;
    margin-bottom: 5rem;
}

.logo-wrap {
    width: 23.9rem;
    height: 5.3rem;
}

.nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 1.5rem;
    width: fit-content;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &.router-link-active {
        font-weight: 700;

        &:hover {
            text-decoration: none;
        }
    }

    img {
        width: 3rem;
        height: 3rem;
        margin-right: 0.6rem;
    }
}

.navbar-bottom {
    padding-top: 2rem;
    border-top: 1px solid white;

    .locales-wrap {
        padding-bottom: 2rem;
        border-bottom: 1px solid white;
        margin-bottom: 2rem;

        .locale-btn {
            padding: 0.5rem 0.8rem;
            border-radius: 0.8rem;
            background: transparent;
            border: none;
            cursor: pointer;
            margin-right: 0.8rem;

            &.active {
                background: $white;
            }
        }
    }
}

.logout-btn {
    background: transparent;
    border: none;
    padding: 0;
}

.nav-bg {
    display: none;
}

@media screen and (max-width: 1199px) and (min-width: 576px) {
    .navbar {
        padding: 5rem 1.5rem 6rem;
        max-width: 7rem;
    }

    .logo-wrap {
        width: 4rem;
    }

    .nav-link {
        padding: 0.5rem;
        border-radius: 1rem;
        transition: background ease-in-out 0.3s;

        &:hover,
        &.router-link-active {
            background: $white;
        }

        img {
            margin-right: 0;
        }

        span {
            display: none;
        }
    }

    .navbar-bottom {
        .locales-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .locale-btn {
                margin: 0 0 0.8rem;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .navbar {
        position: fixed;
        height: auto;
        bottom: 0;
        left: 0;
        max-width: 100%;
        padding: 0 4rem 5rem;
        z-index: 10;
        border-top-left-radius: 3rem;
        border-top-right-radius: 3rem;
        background: $white;
        transform: translateY(100%);

        &.opened {
            transform: translateY(0);
        }
    }

    .nav-bg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.36) 100%);
        z-index: 9;
    }

    .nav-close-btn {
        display: flex;
        justify-content: center;
        padding: 2.5rem 2.5rem;

        svg {
            width: 5.2rem;
            height: 0.6rem;
        }
    }

    .logo-link {
        display: none;
    }

    .navbar-bottom {
        border-top: 1px solid $grey_light;

        .locales-wrap {
            border-bottom: 1px solid $grey_light;

            .locale-btn {
                &.active {
                    background: $grey-light;
                }
            }
        }
    }
}
</style>
