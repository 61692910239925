<template>
    <div>
        <StoryReplyMessage v-if="messageData.reply_to.type === 'story'" :message-data="messageData" />
        <ReplyMessage v-else :message-data="messageData" />
    </div>
</template>

<script>
import StoryReplyMessage from '@/views/chats/messages/message-content/StoryReplyMessage.vue'
import ReplyMessage from '@/views/chats/messages/message-content/ReplyMessage.vue'

export default {
    name: 'ReplyType',
    components: { ReplyMessage, StoryReplyMessage },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';
</style>
