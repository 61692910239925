<template>
    <div class="message-text">
        <img src="@/assets/icons/deleted-message-icon.svg" alt="" />
        <span class="text-grey text-h3">
            {{ $t('components.deletedMessage') }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'DeletedMessage',
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';

.message-text {
    display: flex;
    align-items: center;

    img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.4rem;
    }
}
</style>
