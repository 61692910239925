import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueSortable from 'vue3-sortablejs'
import InfiniteLoading from 'v3-infinite-loading'
import Vue3TouchEvents from 'vue3-touch-events'
import mitt from 'mitt'
import { useAuthStore } from '@/stores/authStore'
import RollbarPlugin from './rollbar'
import i18n from './i18n'
import VueDOMPurifyHTML from 'vue-dompurify-html'

startApp()

async function startApp() {
    const app = createApp(App)
    const eventBus = mitt()

    app.provide('eventBus', eventBus)
    app.component('InfiniteLoading', InfiniteLoading)
    app.use(VueAxios, axios)
    app.use(createPinia())
    app.use(VueSortable)
    app.use(router)
    app.use(Vue3TouchEvents)
    app.use(RollbarPlugin)
    app.use(i18n)
    app.use(VueDOMPurifyHTML)

    try {
        const authStore = useAuthStore()

        await authStore.refreshToken(false)
    } catch {
        // catch error to start app on success or failure
    }

    app.mount('#app')
}
