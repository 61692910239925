<template>
    <div
        class="conversation-item"
        :class="{ hovered: hoveredItem, active: $route.path === `/chats/${conversation.api_id}` }"
    >
        <div class="item-top">
            <router-link
                :to="{ name: 'chatContent', params: { conversation_id: conversation.api_id } }"
                class="user-info"
                @mouseenter="hoveredItem = true"
                @mouseleave="hoveredItem = false"
            >
                <a
                    class="user-link"
                    :href="conversation.user.profile_link"
                    @click="navigate(conversation.user, $event)"
                >
                    <img
                        :src="
                            conversation.user.profile_pic.length
                                ? conversation.user.profile_pic
                                : require('@/assets/icons/instagram-icon.svg')
                        "
                        class="user-img"
                        alt=""
                    />
                </a>

                <div>
                    <div class="user-name">
                        <span class="text-h2-bold">
                            {{ conversation.user.name ? conversation.user.name : conversation.user.username }}
                        </span>
                        <span v-if="!conversation.is_read" class="unread-conversation-mark"></span>
                    </div>

                    <div class="user-message text-h3 text-grey">
                        {{ lastMessageText }}
                    </div>
                </div>
            </router-link>

            <div class="item-info">
                <div class="item-details">
                    <img src="@/assets/icons/message-icon.svg" class="item-type-icon" alt="" />
                    <div class="item-date text-h3-bold">
                        {{ formattedDate }}
                    </div>
                </div>
                <StatusComponent
                    :selected-status-id="conversation.status_id"
                    :statuses="statuses"
                    :conversation-id="conversation.api_id"
                />
            </div>
        </div>
        <div class="item-bottom">
            <div class="managers-list">
                <div v-if="conversation.manager_full_name" class="manager text-h4">
                    {{ conversation.manager_full_name }}
                </div>

                <template v-if="activeManagers.length">
                    <div v-for="(manager, index) in activeManagers" :key="index" class="active-manager text-h4-bold">
                        {{ manager.fullName }}
                    </div>
                </template>
            </div>

            <div class="item-origin">
                <img v-if="getChannelImg" class="origin-img" :src="getChannelImg" alt="" />
                <span class="origin-name text-h4 text-grey">{{ getChannelName }}</span>
            </div>
        </div>

        <LoaderComponent v-if="chatsStore.loadingStatusChange.includes(conversation.id)" />
    </div>
</template>

<script>
import StatusComponent from '@/components/general/StatusComponent.vue'
import { formatDate } from '@/helpers/formatDate'
import { useChatsStore } from '@/stores/chatsStore'
import LoaderComponent from '@/components/general/LoaderComponent.vue'
import { useChannelsStore } from '@/stores/channelsStore'

export default {
    name: 'ConversationComponent',
    components: { LoaderComponent, StatusComponent },
    inject: ['eventBus'],
    props: {
        conversation: {
            type: Object,
            required: true,
        },
        statuses: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const chatsStore = useChatsStore()
        const channelsStore = useChannelsStore()

        return { channelsStore, chatsStore }
    },
    data() {
        return {
            hoveredItem: false,
        }
    },
    computed: {
        formattedDate() {
            if (this.conversation.last_message?.created_time) {
                return formatDate(this.conversation.last_message?.created_time)
            }

            return ''
        },

        getChannelName() {
            return this.channelsStore.channelsList.find((channel) => channel.id === this.conversation.channel_id)?.name
        },

        getChannelImg() {
            return this.channelsStore.channelsList.find((channel) => channel.id === this.conversation.channel_id)
                ?.profile_picture_url
        },

        lastMessageText() {
            const fromChannel = this.conversation.last_message?.from_channel
            const messageText = this.conversation.last_message?.message

            if (messageText?.trim().length === 0 || messageText === null) {
                return fromChannel ? this.$t('components.youSendMessage') : this.$t('components.youReceivedMessage')
            }

            return messageText
        },

        activeManagers() {
            const managers = this.chatsStore.openedConversationsList.filter(
                (conversation) => conversation.conversation_id === this.conversation.api_id
            )

            const uniqueManagers = new Set(managers.map((manager) => `${manager.fullName}_${manager.conversation_id}`))

            return Array.from(uniqueManagers).map((manager) => {
                const [fullName, conversation_id] = manager.split('_')

                return {
                    fullName,
                    conversation_id: parseInt(conversation_id),
                }
            })
        },
    },
    methods: {
        navigate(user, event) {
            this.eventBus.emit('navigateToUserProfile', user)
            event.preventDefault()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.conversation-item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.6rem 3rem;

    &.hovered {
        background: $grey_light;
    }

    &.active {
        background: rgba($blue, 0.1);
    }
}

.item-top {
    display: flex;
    justify-content: space-between;

    .user-info {
        display: flex;
        align-items: center;
        margin-right: 3rem;
        text-decoration: none;
        cursor: pointer;
        flex-grow: 1;
        overflow: hidden;

        .user-link {
            display: flex;
            width: 5rem;
            height: 5rem;
            min-width: 5rem;
            margin-right: 1rem;
        }

        .user-img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        .user-name {
            display: flex;
            align-items: center;
            transition: color ease-in-out 0.2s;
        }

        .unread-conversation-mark {
            display: flex;
            width: 1.7rem;
            height: 1.7rem;
            min-width: 1.7rem;
            min-height: 1.7rem;
            border-radius: 50%;
            background: $blue;
            margin-left: 0.8rem;
        }

        .user-message {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 3.4rem;
        }
    }

    .item-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: fit-content;
        min-width: fit-content;
        padding-top: 0.7rem;

        .item-details {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .item-type-icon {
            width: 2rem;
            height: 2rem;
            margin: 0 0.3rem;
        }

        .item-date {
            white-space: nowrap;
            line-height: 2rem;
        }
    }
}

.item-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
    min-height: 3.2rem;

    .managers-list {
        display: flex;
        flex-wrap: wrap;
    }

    .manager,
    .active-manager {
        padding: 0.4rem 1.6rem 0.5rem;
        border-radius: 0.8rem;
        background: $grey-light;
        margin: 0 0.8rem 0.8rem 0;
    }

    .active-manager {
        position: relative;
        padding: 0.4rem 1.6rem 0.5rem 2rem;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0.8rem;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            background: #1cae04;
            transform: translateY(-50%);
        }
    }

    .item-origin {
        display: flex;
        align-items: center;
        margin-left: auto;

        .origin-img {
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 50%;
            margin-right: 0.6rem;
        }
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .item-top {
        .item-info {
            display: none;
        }

        .user-info {
            .user-link {
                min-width: 3.5rem;
                width: 3.5rem;
                height: 3.5rem;
            }

            .user-name {
                font-size: 1.4rem;
            }

            .user-message {
                display: none;
            }
        }
    }
}
</style>
