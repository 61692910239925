<template>
    <div>
        <div class="message-reels">
            <video @click="showPopup = true">
                <source :src="messageData.attachments[0]?.url" type="video/mp4" />
            </video>
            <img class="video-play-btn" src="@/assets/icons/video-play-icon.svg" alt="" />
        </div>
        <transition name="popup-fade">
            <popup-component
                v-if="showPopup"
                :hide-loader="false"
                :title="messageData?.attachments[0]?.additional_data?.title"
                @close-popup="hidePopup"
            >
                <video v-show="true" autoplay controls class="popup-media">
                    <source :src="messageData.attachments[0]?.url" type="video/mp4" />
                </video>
            </popup-component>
        </transition>
    </div>
</template>

<script>
import PopupComponent from '@/components/general/PopupComponent.vue'

export default {
    name: 'ReelsMessage',
    components: { PopupComponent },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            showPopup: false,
        }
    },
    methods: {
        hidePopup() {
            this.mediaLoaded = false
            this.showPopup = false
        },
    },
}
</script>

<style scoped lang="scss">
.message-reels {
    height: 20rem;
    border-radius: 0.8rem;
    overflow: hidden;
    position: relative;
    background: black;
    display: inline-block;

    video {
        max-height: 20rem;
        height: 100%;
    }

    &:hover {
        cursor: pointer;

        video {
            filter: brightness(0.8);
        }
    }

    video {
        height: 20rem;
        object-fit: cover;
        object-position: center center;
    }

    .video-play-btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 3rem;
        height: 3rem;
        pointer-events: none;
    }
}

.popup-media {
    max-width: 100%;
    max-height: 100%;
}
</style>
