<template>
    <ImageMessage :message-data="messageData" />
</template>

<script>
import ImageMessage from '@/views/chats/messages/message-content/ImageMessage.vue'

export default {
    name: 'ShareMessage',
    components: { ImageMessage },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss">
@import '@/scss/common/colors';
</style>
