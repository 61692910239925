import { defineStore } from 'pinia'
import fetchWrapper from '@/helpers/fetchWrapper'
import { createToaster } from '@meforma/vue-toaster'
import i18n from '@/i18n'

const toaster = createToaster({ position: 'bottom' })
const baseRoute = '/managers'

export const useManagersStore = defineStore('managersStore', {
    state: () => ({
        managersList: [],
        loadingManagers: false,
    }),
    actions: {
        async loadManagers() {
            this.loadingManagers = true
            const lastId = this.managersList[this.managersList.length - 1]?.id

            return await fetchWrapper('/managers/get-list', 'POST', {
                last_id: lastId,
            })
                .then((response) => {
                    const { response: managersResponse } = response

                    this.managersList = this.managersList.concat(managersResponse.managers)

                    return managersResponse.managers
                })
                .finally(() => {
                    this.loadingManagers = false
                })
        },

        async deleteManager(email) {
            await fetchWrapper(`${baseRoute}/hard-delete`, 'DELETE', { email }).then(() => {
                toaster.success(i18n.global.t('managersPage.deletedToast'))
            })
            this.managersList = this.managersList.filter((object) => {
                return object.email !== email
            })
        },

        async addManager(data) {
            await fetchWrapper(`${baseRoute}/add-manager`, 'POST', data).then(() => {
                toaster.success(i18n.global.t('managersPage.addedToast'))
            })
        },
    },
})
