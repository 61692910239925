<template>
    <CardComponent>
        <template #header>
            <h2 class="card-title text-h1">{{ $t('accountPage.myAccount') }}</h2>
        </template>

        <template #body>
            <div class="body-inner">
                <div class="row">
                    <div class="inputs-col col-md-6">
                        <div class="inputs-group-title text-h3">{{ $t('accountPage.personalInfo') }}</div>

                        <InputComponent
                            v-model="personalInfo.name"
                            :input-type="'text'"
                            :input-placeholder="$t('accountPage.name')"
                            :input-value="personalInfo.name"
                            :input-error="v$.personalInfo.name.$errors"
                        />

                        <InputComponent
                            v-model="personalInfo.last_name"
                            :input-type="'text'"
                            :input-placeholder="$t('accountPage.surname')"
                            :input-value="personalInfo.last_name"
                            :input-error="v$.personalInfo.last_name.$errors"
                        />

                        <InputComponent
                            v-model="personalInfo.email"
                            :input-type="'email'"
                            :input-placeholder="'E-mail'"
                            :input-value="personalInfo.email"
                            :input-error="v$.personalInfo.email.$errors"
                        />
                    </div>

                    <div class="inputs-col col-md-6">
                        <div class="inputs-group-title text-h3">{{ $t('accountPage.password') }}</div>

                        <InputComponent
                            v-model="passwordData.current_password"
                            :input-type="'password'"
                            :input-placeholder="$t('accountPage.currentPass')"
                            :input-value="passwordData.current_password"
                            :input-error="v$.passwordData.current_password.$errors"
                        />

                        <InputComponent
                            v-model="passwordData.password"
                            :input-type="'password'"
                            :input-placeholder="$t('accountPage.newPass')"
                            :input-value="passwordData.password"
                            :input-error="v$.passwordData.password.$errors"
                        />

                        <InputComponent
                            v-model="passwordData.repeat_password"
                            :input-type="'password'"
                            :input-placeholder="$t('accountPage.repeatNewPass')"
                            :input-value="passwordData.repeat_password"
                            :input-error="v$.passwordData.repeat_password.$errors"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <button class="btn-black" @click="saveUserInfo">{{ $t('accountPage.save') }}</button>
        </template>
    </CardComponent>
</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required, sameAs } from '@vuelidate/validators'
import fetchWrapper from '@/helpers/fetchWrapper'
import InputComponent from '@/components/general/InputComponent.vue'
import CardComponent from '@/components/general/CardComponent.vue'
import { createToaster } from '@meforma/vue-toaster'

export default {
    name: 'AccountPage',
    components: { InputComponent, CardComponent },
    setup() {
        const authStore = useAuthStore()
        const toaster = createToaster({ position: 'bottom' })

        return { authStore, toaster, v$: useVuelidate() }
    },
    data() {
        return {
            personalInfo: {
                name: '',
                last_name: '',
                email: '',
            },
            passwordData: {
                current_password: '',
                password: '',
                repeat_password: '',
            },
        }
    },
    validations() {
        return {
            personalInfo: {
                name: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
                last_name: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
                email: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                    email: helpers.withMessage(this.$t('validation.email'), email),
                },
            },
            passwordData: {
                current_password: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
                password: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                },
                repeat_password: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                    sameAs: helpers.withMessage(
                        this.$t('validation.passwordMatch'),
                        sameAs(this.passwordData.password)
                    ),
                },
            },
        }
    },
    mounted() {
        document.addEventListener('keyup', this.onKeyUp)
        this.personalInfo = { ...this.authStore.userInfo }
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.onKeyUp)
    },
    methods: {
        onKeyUp(e) {
            if (e.key === 'Enter') {
                this.saveUserInfo()
            }
        },
        async saveUserInfo() {
            if (JSON.stringify(this.authStore.userInfo) !== JSON.stringify(this.personalInfo)) {
                const isFormCorrect = await this.v$.personalInfo.$validate()

                if (isFormCorrect) {
                    await this.updatePersonalInfo()
                }
            }

            if (Object.values(this.passwordData).some((x) => !!x)) {
                const isFormCorrect = await this.v$.passwordData.$validate()

                if (isFormCorrect) {
                    await this.updatePasswordData()
                }
            }
        },

        async updatePersonalInfo() {
            await fetchWrapper('/user/update-personal-info', 'PUT', this.personalInfo).then(() => {
                this.authStore.userInfo = { ...this.personalInfo }
                this.toaster.success(this.$t('accountPage.personalInfoToast'))
                this.afterInfoUpdate()
            })
        },

        async updatePasswordData() {
            await fetchWrapper('/user/update-password', 'PUT', this.passwordData).then(() => {
                this.toaster.success(this.$t('accountPage.passwordToast'))
            })
        },

        afterInfoUpdate() {
            this.authStore.refreshToken()
        },
    },
}
</script>

<style scoped lang="scss">
.body-inner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
