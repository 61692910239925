<template>
    <div class="conversations-filter">
        <div class="filter">
            <div class="filter-trigger" :class="{ applied: filterApplied }" @click="showDropdown = true">
                <span class="text-h3">{{ $t('chatsPage.filter') }}</span>
                <img src="@/assets/icons/filter-icon.svg" class="filter-icon" alt="" />
            </div>
            <div v-if="showDropdown" v-click-outside="cancelFilter" class="filter-dropdown">
                <div class="clear-filter" @click="clearFilter">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 2.25C10.4142 2.25 10.75 2.58579 10.75 3C10.75 3.41421 10.4142 3.75 10 3.75C8.76387 3.75 7.55549 4.11656 6.52769 4.80331C5.49988 5.49007 4.6988 6.46619 4.22575 7.60823C3.75271 8.75027 3.62894 10.0069 3.87009 11.2193C4.11125 12.4317 4.70651 13.5453 5.58058 14.4194C6.45466 15.2935 7.56831 15.8887 8.78069 16.1299C9.99307 16.3711 11.2497 16.2473 12.3918 15.7742C13.5338 15.3012 14.5099 14.5001 15.1967 13.4723C15.8834 12.4445 16.25 11.2361 16.25 10C16.25 8.16712 15.485 6.50196 14.25 5.35216V7.375C14.25 7.78921 13.9142 8.125 13.5 8.125C13.0858 8.125 12.75 7.78921 12.75 7.375V3.88822C12.7487 3.81971 12.7568 3.75056 12.7749 3.68273C12.7983 3.59397 12.8377 3.51167 12.8897 3.43903C12.953 3.35006 13.0332 3.27854 13.1232 3.22635C13.2131 3.17404 13.3149 3.13987 13.4234 3.12886C13.4536 3.12574 13.4841 3.12443 13.5146 3.125H17C17.4142 3.125 17.75 3.46079 17.75 3.875C17.75 4.28921 17.4142 4.625 17 4.625H15.6439C16.9596 6.03045 17.75 7.93523 17.75 10C17.75 11.5328 17.2955 13.0312 16.4439 14.3057C15.5923 15.5801 14.3819 16.5735 12.9658 17.1601C11.5497 17.7466 9.9914 17.9001 8.48805 17.6011C6.9847 17.302 5.60378 16.5639 4.51992 15.4801C3.43607 14.3962 2.69795 13.0153 2.39892 11.5119C2.09988 10.0086 2.25336 8.45033 2.83993 7.0342C3.42651 5.61808 4.41985 4.40769 5.69433 3.55611C6.96881 2.70453 8.4672 2.25 10 2.25Z"
                            fill="black"
                        />
                    </svg>
                </div>
                <div class="filter-body">
                    <div class="filter-group">
                        <div class="group-title text-h3 text-grey">{{ $t('chatsPage.channels') }}</div>
                        <label class="group-item checkbox-group">
                            <input
                                class="group-input"
                                type="checkbox"
                                :checked="!appliedFilters.channels.length"
                                @change="showAllChannels"
                            />
                            <span class="text-h2">{{ $t('chatsPage.allChannels') }}</span>
                        </label>
                        <label
                            v-for="(channel, index) in channelsStore.channelsList"
                            :key="index"
                            class="group-item checkbox-group"
                        >
                            <input
                                class="group-input"
                                type="checkbox"
                                :checked="isFilterActive('channels', channel.id)"
                                @change="changeFilterData('channels', channel.id)"
                            />
                            <span class="text-h2">{{ channel.name }}</span>
                        </label>
                    </div>

                    <div class="filter-group">
                        <div class="group-title text-h3 text-grey">{{ $t('chatsPage.managers') }}</div>
                        <label class="group-item checkbox-group">
                            <input
                                class="group-input"
                                type="checkbox"
                                :checked="!appliedFilters.managers.length"
                                @change="showAllManagers"
                            />
                            <span class="text-h2">{{ $t('chatsPage.allManagers') }}</span>
                        </label>
                        <label class="group-item checkbox-group">
                            <input
                                class="group-input"
                                type="checkbox"
                                :checked="isFilterActive('managers', -1)"
                                @change="changeFilterData('managers', -1)"
                            />
                            <span class="text-h2">{{ $t('chatsPage.withoutManager') }}</span>
                        </label>
                        <label
                            v-for="(manager, index) in managersStore.managersList"
                            :key="index"
                            class="group-item checkbox-group"
                        >
                            <input
                                class="group-input"
                                type="checkbox"
                                :checked="isFilterActive('managers', manager.id)"
                                @change="changeFilterData('managers', manager.id)"
                            />
                            <span class="text-h2">{{ manager.full_name }}</span>
                        </label>
                    </div>
                </div>

                <div class="filter-bottom">
                    <div class="cancel-filter-btn filter-btn btn-style text-h2 text-grey" @click="cancelFilter">
                        {{ $t('chatsPage.cancel') }}
                    </div>
                    <div class="submit-filter-btn filter-btn btn-black" @click="submitFilter">
                        {{ $t('chatsPage.apply') }}
                    </div>
                </div>
            </div>

            <LoaderComponent v-if="chatsStore.loadingConversations" />
        </div>
    </div>
</template>

<script>
import LoaderComponent from '@/components/general/LoaderComponent.vue'
import { useChatsStore } from '@/stores/chatsStore'
import { useChannelsStore } from '@/stores/channelsStore'
import vClickOutside from 'click-outside-vue3'
import { useManagersStore } from '@/stores/managersStore'

export default {
    name: 'ConversationsFilter',
    components: { LoaderComponent },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    setup() {
        const chatsStore = useChatsStore()
        const channelsStore = useChannelsStore()
        const managersStore = useManagersStore()

        return { chatsStore, managersStore, channelsStore }
    },
    data() {
        return {
            showDropdown: false,
            appliedFilters: {
                channels: [],
                managers: [],
            },
        }
    },
    computed: {
        filterApplied() {
            return (
                this.chatsStore.conversationsFilters?.channels?.length >= 1 ||
                this.chatsStore.conversationsFilters?.managers?.length >= 1
            )
        },
    },
    watch: {
        showDropdown: {
            handler(newVal) {
                if (newVal) {
                    this.appliedFilters.channels = [...this.chatsStore.conversationsFilters.channels]
                    this.appliedFilters.managers = [...this.chatsStore.conversationsFilters.managers]
                }
            },
        },
    },
    methods: {
        hideDropdown() {
            this.showDropdown = false
        },

        changeFilterData(type, id) {
            if (this.appliedFilters[type]?.includes(id)) {
                this.appliedFilters[type].splice(this.appliedFilters[type].indexOf(id), 1)
            } else {
                this.appliedFilters[type]?.push(id)
            }
        },

        submitFilter() {
            this.chatsStore.conversationsFilters.channels = [...this.appliedFilters.channels]
            this.chatsStore.conversationsFilters.managers = [...this.appliedFilters.managers]
            this.chatsStore.conversationsList = []
            this.chatsStore.conversationsPaging = {}
            this.chatsStore.loadConversations()
            this.hideDropdown()
        },

        showAllChannels() {
            this.appliedFilters.channels = []
        },

        showAllManagers() {
            this.appliedFilters.managers = []
        },

        clearFilter() {
            this.appliedFilters.channels = []
            this.appliedFilters.managers = []

            this.submitFilter()
        },

        cancelFilter() {
            this.appliedFilters.channels = this.chatsStore.conversationsFilters.channels
            this.appliedFilters.managers = this.chatsStore.conversationsFilters.managers
            this.hideDropdown()
        },

        isFilterActive(type, id) {
            return this.appliedFilters?.[type]?.includes(id)
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';

.filter {
    position: relative;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.filter-trigger {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        span {
            color: $blue;
        }
    }

    &.applied {
        &:after {
            content: '';
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: -2px;
            right: -2px;
            background: $blue;
            border-radius: 50%;
        }
    }

    span {
        margin-right: 0.5rem;
    }
}

.filter-dropdown {
    position: absolute;
    top: 2.8rem;
    right: 0.5rem;
    background: $white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    z-index: 100;
    padding: 3rem 4rem;
    min-width: 35rem;
}

.clear-filter {
    display: flex;
    position: absolute;
    right: 6.5rem;
    top: 2.75rem;
    cursor: pointer;

    &:hover {
        path {
            fill: $blue;
        }
    }
}

.filter-body {
    overflow: auto;
    max-height: 35rem;
    margin-bottom: 2rem;
}

.filter-group {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey_light;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }

    .group-title {
        margin-bottom: 2rem;
    }

    .group-item {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .group-input {
        margin-right: 0.5rem;
    }
}

.filter-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-btn {
        padding: 0.5rem 1.5rem;
        width: calc(50% - 1rem);
        min-width: calc(50% - 1rem);
        cursor: pointer;
    }

    .cancel-filter-btn {
        &:hover {
            background: $grey;
            color: $white;
        }
    }
}

@media screen and (max-width: 767px) {
    .filter-dropdown {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 3rem 2rem;
    }

    .clear-filter {
        right: 3.5rem;
    }

    .filter-body {
        max-height: 100%;
        height: 100%;
    }
}
</style>
