<template>
    <FullPageLayout>
        <h1 v-if="createManager" class="form-title text-h1 text-center">{{ $t('authPage.endRegTitle') }}</h1>
        <h1 v-else class="form-title text-h1 text-center">{{ $t('authPage.createTitle') }}</h1>

        <p v-if="createManager" class="form-description text-h2 text-center">{{ $t('authPage.endRegDescription') }}</p>
        <p v-else class="form-description text-h2 text-center">{{ $t('authPage.createDescription') }}</p>

        <form class="sign-form" @submit.prevent="newPassSet">
            <InputComponent
                v-model="newPassForm.password"
                :new-style="true"
                :input-type="'password'"
                :input-label="createManager ? $t('authPage.enterPass') : $t('authPage.enterNewPass')"
                :input-placeholder="createManager ? $t('authPage.enterPass') : $t('authPage.enterNewPass')"
                :input-value="newPassForm.password"
                :input-error="v$.newPassForm.password.$errors"
            />

            <InputComponent
                v-model="newPassForm.repeatPassword"
                :new-style="true"
                :input-type="'password'"
                :input-label="createManager ? $t('authPage.repeatPass') : $t('authPage.repeatNewPass')"
                :input-placeholder="createManager ? $t('authPage.repeatPass') : $t('authPage.repeatNewPass')"
                :input-value="newPassForm.repeatPassword"
                :input-error="v$.newPassForm.repeatPassword.$errors"
            />

            <button type="submit" class="form-btn btn-gradient">{{ $t('authPage.savePass') }}</button>
        </form>
    </FullPageLayout>
</template>

<script>
import FullPageLayout from '@/layouts/FullPageLayout.vue'
import InputComponent from '@/components/general/InputComponent.vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, sameAs } from '@vuelidate/validators'
import { useAuthStore } from '@/stores/authStore'

export default {
    name: 'NewPassPage',
    components: { InputComponent, FullPageLayout },
    props: {
        createManager: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const authStore = useAuthStore()

        return { authStore, v$: useVuelidate() }
    },
    data() {
        return {
            newPassForm: {
                password: null,
                repeatPassword: null,
            },
        }
    },
    validations() {
        return {
            newPassForm: {
                password: { required: helpers.withMessage(this.$t('validation.required'), required) },
                repeatPassword: {
                    required: helpers.withMessage(this.$t('validation.required'), required),
                    sameAs: helpers.withMessage(this.$t('validation.passwordMatch'), sameAs(this.newPassForm.password)),
                },
            },
        }
    },
    methods: {
        async newPassSet() {
            const isFormCorrect = await this.v$.newPassForm.$validate()

            if (isFormCorrect) {
                this.createManager ? this.createManagerPass() : this.createNewPass()
            }
        },

        createNewPass() {
            this.authStore.createNewPass({
                token: this.$route.params.token,
                email: this.$route.query.email,
                password: this.newPassForm.password,
            })
        },

        createManagerPass() {
            this.authStore.createManagerPass(this.$route.params.token, this.newPassForm.password)
        },
    },
}
</script>

<style lang="scss" scoped>
.form-btn {
    max-width: 100%;
}
</style>
