<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'QuickRepliesPage',
}
</script>

<style lang="scss" scoped></style>
