<template>
    <div class="loader-overlay">
        <div class="loading">
            <div class="bubble-wrap">
                <div class="bubble"></div>
            </div>
            <div class="bubble-wrap">
                <div class="bubble"></div>
            </div>
            <div class="bubble-wrap">
                <div class="bubble"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoaderComponent',
}
</script>

<style scoped lang="scss">
.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(black, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-bubbles {
    margin: auto;
}

.bubble-wrap {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;

    &:nth-of-type(2n) {
        .bubble {
            animation-delay: -0.3s;
        }
    }

    &:nth-of-type(3n) {
        .bubble {
            animation-delay: 0s;
        }
    }
}

.bubble {
    border-radius: 50%;
    background-color: #fff;
    width: 100%;
    height: 100%;
    transform-origin: 50% 50%;
    animation: bubble 1.2s -0.6s infinite ease-out;
}

@keyframes bubble {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
</style>
