<template>
    <div class="status-wrap" :class="{ centered: centeredMode }">
        <div class="selected-status text-h4 text-blue" :class="{ active: showDropdown }" @click="showDropdown = true">
            {{ currentStatus }}
        </div>

        <div v-if="showDropdown" v-click-outside="hideDropdown" class="status-dropdown">
            <template v-for="(item, index) in statuses" :key="index">
                <div
                    v-if="item.id !== selectedStatusId"
                    class="dropdown-item text-h3"
                    @click.stop.prevent="selectStatus(item)"
                >
                    {{ item.name }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import { useChatsStore } from '@/stores/chatsStore'
import lodash from 'lodash'

export default {
    name: 'StatusComponent',
    directives: {
        clickOutside: vClickOutside.directive,
    },
    props: {
        selectedStatusId: {
            type: [String, Number],
            default: null,
        },
        statuses: {
            type: Array,
            required: true,
        },
        conversationId: {
            type: [String, Number],
            required: true,
        },
        centeredMode: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const chatsStore = useChatsStore()

        return { chatsStore }
    },
    data() {
        return {
            showDropdown: false,
        }
    },
    computed: {
        currentStatus() {
            const status = lodash.find(this.statuses, (object) => {
                return object.id === this.selectedStatusId
            })

            return status ? status.name : this.$t('components.status')
        },
    },
    methods: {
        hideDropdown() {
            this.showDropdown = false
        },
        selectStatus({ id }) {
            this.chatsStore.changeConversationStatus(this.conversationId, id)
            this.hideDropdown()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.status-wrap {
    position: relative;

    &.centered {
        .selected-status {
            min-width: auto;
        }

        .status-dropdown {
            right: 50%;
            transform: translateX(50%);
        }
    }
}

.conversation-info .selected-status {
    max-width: 18rem;
}

.selected-status {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 1.8rem;
    max-width: 9rem;
    min-width: 8rem;
    padding: 0.3rem 0;
    text-align: right;

    &.active {
        color: $black;
    }

    &:hover {
        cursor: pointer;
        color: $black;
    }
}

.status-dropdown {
    position: absolute;
    top: 2rem;
    right: 0;
    background: $white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 40rem;

    .dropdown-item {
        padding: 1rem 1.6rem;
        border-bottom: 1px solid $grey_light;
        border-radius: 1rem;
        min-width: 25rem;
        max-width: 25rem;

        &:hover {
            cursor: pointer;
            background: rgba($grey_light, 0.5);
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}
</style>
