<template>
    <div class="quick-reply input-button">
        <svg
            class="input-button-icon"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="{ active: showReplyDropdown }"
            @click="openReplyDropdown"
        >
            <circle cx="15" cy="15" r="10" stroke="black" stroke-width="1.5" />
            <path d="M15 11V19" stroke="black" stroke-width="1.5" stroke-linecap="round" />
            <path d="M19 15L11 15" stroke="black" stroke-width="1.5" stroke-linecap="round" />
        </svg>

        <div v-if="showReplyDropdown" v-click-outside="hideReplyDropdown" class="reply-dropdown">
            <div class="dropdown-header">
                <span class="text-h2-bold">Швидкі відповіді</span>
                <router-link class="create-reply-link" :to="{ name: 'createReply' }" title="Додати швидку відповідь">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15" cy="15" r="10" stroke="#0081DC" stroke-width="1.5" />
                        <path d="M15 11V19" stroke="#0081DC" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M19 15L11 15" stroke="#0081DC" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </router-link>
            </div>

            <div class="replies-list">
                <div
                    v-for="(reply, index) in quickRepliesStore.quickRepliesList"
                    :key="index"
                    class="reply-item"
                    @click="selectReply(reply.text)"
                >
                    <span class="reply-name text-h4 text-grey">{{ reply.name }}</span>
                    <span class="reply-text text-h3-bold">{{ reply.text }}</span>
                </div>

                <LoaderComponent v-if="quickRepliesStore.loadingQuickReplies" />
                <infinite-loading target=".replies-list" :distance="10" @infinite="infiniteHandler"></infinite-loading>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import { useQuickRepliesStore } from '@/stores/quickRepliesStore'
import LoaderComponent from '@/components/general/LoaderComponent.vue'

export default {
    name: 'QuickReplyComponent',
    components: { LoaderComponent },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    emits: ['replyDropdownOpened', 'selectReply'],
    setup() {
        const quickRepliesStore = useQuickRepliesStore()

        return { quickRepliesStore }
    },
    data() {
        return {
            showReplyDropdown: false,
        }
    },
    methods: {
        openReplyDropdown() {
            this.showReplyDropdown = true
            this.$emit('replyDropdownOpened', true)
        },

        hideReplyDropdown() {
            this.showReplyDropdown = false
            this.$emit('replyDropdownOpened', false)
        },

        selectReply(text) {
            this.$emit('selectReply', text)
            this.hideReplyDropdown()
        },
        infiniteHandler() {
            this.quickRepliesStore.loadQuickReplies()
        },
    },
}
</script>

<style scoped lang="scss">
@import '@/scss/common/colors';

.quick-reply {
    position: relative;
}

.input-button-icon {
    display: flex;

    circle,
    path {
        stroke: $black;
        transition: all ease-in-out 0.3s;
    }

    &.active,
    &:hover {
        cursor: pointer;

        circle,
        path {
            stroke: $blue;
        }
    }
}

.reply-dropdown {
    position: absolute;
    bottom: 3rem;
    right: 0;
    padding: 3rem 0;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    z-index: 100;
    min-width: 40rem;
    overflow: hidden;
}

.dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 1.5rem;
}

.create-reply-link {
    display: flex;

    circle,
    path {
        stroke: $blue;
        transition: all ease-in-out 0.3s;
    }

    &:hover {
        circle,
        path {
            stroke: $black;
        }
    }
}

.replies-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    max-height: min(57.5rem, 50vh);
}

.reply-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.6rem 2rem;
    border-bottom: 1px solid $grey_light;

    &:first-of-type {
        border-top: 1px solid $grey_light;
    }

    &:hover {
        cursor: pointer;
        background: $grey_light;
    }
}

.reply-name,
.reply-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.reply-name {
    margin-bottom: 0.3rem;
    max-height: 1.5rem;
}

.reply-text {
    max-height: 1.7rem;
}

@media screen and (max-width: 1500px){
    .reply-dropdown {
        min-width: 32rem;
    }
}

@media screen and (max-width: 475px){
    .reply-dropdown {
        right: -8rem;
    }
}
</style>
