<template>
    <div>
        <ReplyMessageWrapper :message-data="messageData.reply_to.message" />
        <div class="message-text text-h3">
            {{ messageData.message }}
        </div>
    </div>
</template>

<script>
import ReplyMessageWrapper from '@/views/chats/messages/ReplyMessageWrapper.vue'

export default {
    name: 'ReplyMessage',
    components: { ReplyMessageWrapper },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.message-text {
    margin-top: 0.5rem;
}
</style>
