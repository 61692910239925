<template>
    <div class="message" :class="{ 'my-message': isMyMessage }">
        <img v-if="!isMyMessage" :src="messageAuthorImg" class="message-author-img" alt="" />

        <div class="message-body">
            <div class="body-inner">
                <component :is="componentName[messageData.type]" :message-data="messageData" />

                <div v-if="messageData.reactions?.length" class="message-reactions">
                    <transition-group name="reaction">
                        <div v-for="(reaction, index) in messageData.reactions" :key="index" class="reaction">
                            {{ reaction.emoji }}
                        </div>
                    </transition-group>
                </div>
            </div>

            <div class="message-info">
                <div v-if="messageData.is_seen && isMyMessage" class="message-seen">
                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.19581 6.86323C1.45688 6.60288 1.88017 6.60288 2.14124 6.86323L4.81533 9.5299C5.07641 9.79025 5.07641 10.2124 4.81533 10.4727C4.55426 10.7331 4.13097 10.7331 3.8699 10.4727L1.19581 7.80604C0.934731 7.54569 0.934731 7.12358 1.19581 6.86323Z"
                            fill="#0081DC"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.87549 7.52925C6.13657 7.2689 6.55985 7.2689 6.82093 7.52925L8.8265 9.52925C9.08757 9.7896 9.08757 10.2117 8.8265 10.4721C8.56542 10.7324 8.14214 10.7324 7.88106 10.4721L5.87549 8.47206C5.61442 8.21171 5.61442 7.7896 5.87549 7.52925Z"
                            fill="#0081DC"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.1637 4.19526C10.4248 4.45561 10.4248 4.87772 10.1637 5.13807L4.81556 10.4714C4.55448 10.7318 4.1312 10.7318 3.87012 10.4714C3.60905 10.2111 3.60905 9.78894 3.87012 9.5286L9.21831 4.19526C9.47938 3.93491 9.90267 3.93491 10.1637 4.19526Z"
                            fill="#0081DC"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.175 4.19526C14.436 4.45561 14.436 4.87772 14.175 5.13807L8.82679 10.4714C8.56571 10.7318 8.14243 10.7318 7.88135 10.4714C7.62028 10.2111 7.62028 9.78894 7.88135 9.5286L13.2295 4.19526C13.4906 3.93491 13.9139 3.93491 14.175 4.19526Z"
                            fill="#0081DC"
                        />
                    </svg>
                </div>

                <div class="message-manager text-h4 text-grey">
                    {{ messageData.manager_full_name }}
                </div>

                <div class="message-time text-h4 text-grey">
                    {{ formattedDate }}
                </div>

                <div v-if="messageData.loading" class="media-loading"></div>

                <div v-if="messageData.loadingError" class="loading-error">
                    <img src="@/assets/icons/message-error-icon.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDate } from '@/helpers/formatDate'
import { useChatsStore } from '@/stores/chatsStore'
import TextType from '@/views/chats/messages/message-types/TextType.vue'
import ReplyType from '@/views/chats/messages/message-types/ReplyType.vue'
import AttachmentType from '@/views/chats/messages/message-types/AttachmentType.vue'
import UnsupportedType from '@/views/chats/messages/message-types/UnsupportedType.vue'
import DeletedType from '@/views/chats/messages/message-types/DeletedType.vue'

export default {
    name: 'MessageWrapper',
    components: {
        TextType,
        ReplyType,
        AttachmentType,
        UnsupportedType,
        DeletedType,
    },
    props: {
        messageData: {
            type: Object,
            default: () => {},
        },
    },
    setup() {
        const chatsStore = useChatsStore()

        return { chatsStore }
    },
    data() {
        return {
            componentName: {
                text: 'TextType',
                attachment: 'AttachmentType',
                unsupported: 'UnsupportedType',
                reply: 'ReplyType',
                deleted: 'DeletedType',
            },
        }
    },

    computed: {
        formattedDate() {
            return formatDate(this.messageData.created_time)
        },

        messageAuthorImg() {
            return this.chatsStore.messagesParticipants[this.messageData.from]?.profile_pic
                ? this.chatsStore.messagesParticipants[this.messageData.from]?.profile_pic
                : require('@/assets/icons/instagram-icon.svg')
        },

        isMyMessage() {
            return this.chatsStore.messagesParticipants[this.messageData.from]?.channel_account
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.message {
    display: flex;
    align-items: flex-end;
    max-width: 80%;
    margin-bottom: 1rem;

    &.my-message {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .message-body {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            max-width: 100%;

            .message-reactions {
                right: unset;
                left: calc(-2.5rem + 0.5rem);
            }
        }
    }

    .body-inner {
        position: relative;
        max-width: 100%;

        .message-reactions {
            width: 2.8rem;
            height: 2.5rem;
            position: absolute;
            bottom: 0;
            right: calc(-2.5rem + 0.5rem);
        }

        .reaction {
            padding: 0.6rem;
            font-size: 1.3rem;
            line-height: 1.3rem;
            border-radius: 0.8rem;
            background: $white;
            box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.15);
        }
    }

    .message-info {
        display: flex;
        align-items: center;
        margin-top: 0.6rem;

        .message-seen {
            margin-right: 0.4rem;
            width: 1.6rem;
            height: 1.5rem;

            svg {
                width: 1.6rem;
                height: 1.5rem;
            }
        }

        .message-manager {
            margin-right: 0.5rem;
        }

        .media-loading {
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            border: 2px solid $blue;
            border-bottom-color: transparent;
            border-radius: 50%;
            animation: rotation 1s linear infinite;
            transform-origin: center;
            margin-left: 0.5rem;
        }

        .loading-error {
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            margin-left: 0.5rem;
        }
    }
}

.message-author-img {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    margin-right: 0.8rem;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

<style lang="scss">
@import '@/scss/common/colors';

.my-message {
    .message-text {
        border-radius: 0.8rem 0.8rem 0 0.8rem;
        background: rgba(#0081dc, 0.1);
    }
}

.message-text {
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem 0.8rem 0.8rem 0;
    width: fit-content;
    background: $grey-light;
}
</style>
